import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

const state = {
  hideConfigButton: false,
  isPinned: true,
  showConfig: false,
  isRTL: false,
  color: "",
  sidebarType: "bg-white",
  darkMode: false,
  isNavFixed: true,
  isAbsolute: false,
  showNavs: true,
  showSidenav: true,
  showNavbar: true,
  showFooter: true,
  showMain: true,
  layout: "default",
  bootstrap,
};

const getters = {
  hideConfigButton: (state) => state.hideConfigButton,
  isPinned: (state) => state.isPinned,
  showConfig: (state) => state.showConfig,
  isRTL: (state) => state.isRTL,
  color: (state) => state.color,
  sidebarType: (state) => state.sidebarType,
  darkMode: (state) => state.darkMode,
  isNavFixed: (state) => state.isNavFixed,
  isAbsolute: (state) => state.isAbsolute,
  showNavs: (state) => state.showNavs,
  showSidenav: (state) => state.showSidenav,
  showNavbar: (state) => state.showNavbar,
  showFooter: (state) => state.showFooter,
  showMain: (state) => state.showMain,
  layout: (state) => state.layout,
  bootstrap: (state) => state.bootstrap,
};

const mutations = {
  toggleConfigurator(state) {
    state.showConfig = !state.showConfig;
  },
  navbarMinimize(state) {
    const sidenav_show = document.querySelector("#app");

    if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
      sidenav_show.classList.add("g-sidenav-pinned");
      sidenav_show.classList.remove("g-sidenav-hidden");
      state.isPinned = true;
    } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
      state.isPinned = false;
    } else if (sidenav_show.classList.contains("g-sidenav-show") && window.innerWidth < 1200) {
      sidenav_show.classList.add("g-sidenav-pinned");
      state.isPinned = true;
    } else {
      sidenav_show.classList.add("g-sidenav-hidden");
      state.isPinned = true;
    }
  },
  setSidebarType(state, payload) {
    state.sidebarType = payload;
  },
  navbarFixed(state) {
    if (state.isNavFixed === false) {
      state.isNavFixed = true;
    } else {
      state.isNavFixed = false;
    }
  },
  toggleDefaultLayout(state) {
    state.showNavbar = !state.showNavbar;
    state.showSidenav = !state.showSidenav;
    state.showFooter = !state.showFooter;
  },
  toggleDarkMode(state, flag) {
    state.darkMode = flag;
  },
  toggleConfigButton(state, flag) {
    state.hideConfigButton = flag;
  },
  toggleShowNavbar(state, flag) {
    state.showNavbar = flag;
  },
  toggleShowFooter(state, flag) {
    state.showFooter = flag;
  },
  changeLayout(state, layout) {
    state.layout = layout;
  },
  toggleIsAbsolute(state, flag) {
    state.isAbsolute = flag;
  },
  toggleIsPinned(state, flag) {
    state.isPinned = flag;
  },
  toggleIsRTL(state, flag) {
    state.isRTL = flag;
  },
  toggleSidenav(state, flag) {
    state.showSidenav = flag;
  },
};

const actions = {
  toggleSidebarColor({ commit }, payload) {
    commit("setSidebarType", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};