<template>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2">Sign in</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <form role="form" class="text-start">
                  <!-- <div class="mb-3">
                    <argon-input id="email" type="email" placeholder="Email" aria-label="Email" />
                  </div>
                  <div>
                    <argon-input id="password" type="password" placeholder="Password" aria-label="Password" />
                  </div> -->
                  <div class="text-center">
                    <argon-button color="success" variant="gradient" full-width class="my-4 mb-2"
                      @click.prevent="onLoginPress">Sign in</argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";

export default {
  name: "Signin",
  components: {
    // ArgonInput,
    ArgonButton,
  },
  created() {
    this.toggleConfigButton(true);
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.toggleConfigButton(false);
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations("theme", ["toggleDefaultLayout", "toggleConfigButton"]),
    onLoginPress() {
      this.$auth0.loginWithRedirect();
    }
  },
};
</script>
