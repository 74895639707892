<template>
  <div class="accordion-item">
    <h5 :id="accordionId" class="accordion-header">
      <button class="accordion-button font-weight-bold" :class="[accordianButtonClass]" type="button"
        data-bs-toggle="collapse" :data-bs-target="`#${collapseId}`" :aria-expanded="active ? 'true' : 'false'"
        :aria-controls="collapseId">
        <slot name="question" />
        <i class="pt-1 text-xs collapse-close fa fa-chevron-down position-absolute end-0 me-3"></i>
        <!-- <i class="pt-1 text-xs collapse-close fa fa-plus position-absolute end-0 me-3"></i>
        <i class="pt-1 text-xs collapse-open fa fa-minus position-absolute end-0 me-3"></i> -->
      </button>
    </h5>
    <div :id="collapseId" class="accordion-collapse collapse" :class="active ? 'show' : ''"
      :aria-labelledby="accordionId" :data-bs-parent="`#${parentId}`">
      <div class="text-sm accordion-body opacity-8" :class="[accordianBodyClass]">
        <slot name="answer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionItem",
  props: {
    accordionId: {
      type: String,
      default: "",
    },
    collapseId: {
      type: String,
      default: "",
    },
    parentId: {
      type: String,
      default: "default",
    },
    active: {
      type: Boolean,
      default: false,
    },
    accordianButtonClass: {
      type: String,
      default: '',
    },
    accordianBodyClass: {
      type: String,
      default: '',
    },
  },
};
</script>
