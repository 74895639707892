<template>
  <div style="min-height: 85vh;">
    <div class="card">
      <div class="card-body py-4">
        <div class="row">
          <div class="col-12">
            <h5 class="title-color">Please enter data to predict case outcome</h5>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4 col-sm-12">
            <label class="form-label title-color">Industry</label>
            <select id="prediction_industry" class="form-control choices__inner border-0" name="prediction_industry"
              @change="onIndustryChange($event.target.value)" />
          </div>
          <div class="col-md-4 col-sm-12">
            <label class="form-label title-color">Sub-Industries</label>
            <select id="prediction_sub_industry" class="form-control choices__inner border-0"
              name="prediction_sub_industry" @change="onSubIndustryChange($event.target.value)" />
          </div>
          <div class="col-md-4 col-sm-12">
            <label class="form-label title-color">Point of Law</label>
            <select id="prediction_point_of_law" class="form-control choices__inner border-0"
              name="prediction_point_of_law" @change="onPointOfLawChange($event.target.value)" />
          </div>
        </div>
        <!-- <argon-button color="primary" class="mt-4" @click.prevent="predictCase">Predict case</argon-button> -->
        <p class="mt-4 mb-0">
          According to our statistics, we predict that the claimant would win with a probability of <span
            class="border border-success p-1 text-success">{{ percentage }}%</span>
          and a tribunal award of <span class="border border-primary p-1">{{ amount }}</span>
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card overflow-auto mb-0">
          <div id="prediction_cases_table" class="card-body p-3 pt-0 mt-3 overflow-auto" style="max-height: 400px;">
            <table class="table table-sm table-striped table-hover" style="table-layout: fixed; word-wrap: break-word;">
              <thead>
                <th scope="col">Case Name</th>
                <th scope="col">Date</th>
                <th scope="col">Claimant</th>
                <th scope="col">Case Type</th>
                <th scope="col">Respondent</th>
                <th scope="col">Point of Law</th>
                <th scope="col">Case Outcome</th>
                <th scope="col">Tribunal Award</th>
              </thead>
              <tbody>
                <tr
                  v-for="(cases, i) of caseTable.slice(pageNo == 1 ? 0 : ((pageNo - 1) * dataSize), pageNo * dataSize)"
                  :key="`${i}.${cases.id} `">
                  <td class="value text-start align-baseline">
                    <span v-if="isNewCase(cases)" class="badge badge-sm bg-primary text-white me-1 mt-1">
                      New
                    </span>
                    <span class="text-capitalize" :class="[cases.id ? 'case_title' : '']"
                      @click="activeCaseDetailsTab(cases.id)">{{ cases.caseName || '-' }}</span>
                  </td>
                  <td class="value text-center text-capitalize align-baseline">
                    {{ !cases.caseDate ? '-' : `${getFormatedDate(cases.caseDate, '', '', true)}` }}
                  </td>
                  <td class="value text-center text-capitalize align-baseline">
                    {{ cases.claimant ? capitalizeFirstLetter(cases.claimant) : '-' }}
                  </td>
                  <td class="value text-center text-capitalize align-baseline">{{ cases.caseType || '-' }}</td>
                  <td class="value text-capitalize align-baseline">
                    {{ cases?.respondent && cases?.respondent.length > 0 ? cases?.respondent.join(", ") : '-' }}
                  </td>
                  <td class="value align-baseline">
                    <div v-if="cases.category && cases.category.length"
                      class="d-flex align-items-center flex-wrap gap-2">
                      <span v-for="code of cases.category" :key="code"
                        class="badge badge-sm bg-secondary text-wrap text-break text-start lh-base"
                        style="text-transform: none !important;">
                        {{ code }}
                      </span>
                    </div>
                    <span v-else class="text-center">-</span>
                  </td>
                  <td class="value text-capitalize align-baseline">
                    <div v-if="cases.result_aggregated && cases.result_aggregated.length"
                      class="d-flex align-items-center flex-wrap gap-2">
                      <span v-for="(result, ind) of cases.result_aggregated" :key="`${ind}.${result}`"
                        class="badge badge-sm text-wrap text-break text-capitalize text-start lh-base" :class="{
                'bg-primary': String(result).toLowerCase().includes('respondent'),
                'bg-purple': String(result).toLowerCase().includes('claimant'),
                'bg-orange': String(result).toLowerCase().includes('settled'),
                'bg-dark': String(result).toLowerCase().includes('not decided yet'),
              }">
                        {{ result }}
                      </span>
                    </div>
                    <span v-else class="text-center">-</span>
                  </td>
                  <td class="value text-capitalize align-baseline">
                    {{ getTribunalAward(cases?.orderedPayment_aggregated || []) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="caseTable.length == 0">
              <h4 class="text-center text-lg">No cases found</h4>
            </div>
          </div>
          <div v-if="caseTable.length > dataSize" class="card-footer px-0">
            <div class="pagination-container">
              <vue-awesome-paginate v-model="pageNo" :max-pages-shown="3" :on-click="onPageNoChange"
                :items-per-page="dataSize" :total-items="caseTable.length">
                <template #prev-button>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="12" height="12" viewBox="0 0 24 24">
                    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                  </svg>
                </template>
                <template #next-button>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" width="12" height="12" viewBox="0 0 24 24">
                      <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
                    </svg>
                  </span>
                </template>
              </vue-awesome-paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Choices from 'choices.js';
import { mapGetters, mapMutations } from 'vuex';

// import ArgonButton from "@/components/ArgonButton.vue";

import {
  isNewCase,
  nFormatter,
  getNumberOnly,
  generateRandom,
  getFormatedDate,
  capitalizeFirstLetter,
} from "@/utils/utils.js";

export default {
  name: "PredictionComponent",
  // components: { ArgonButton },
  data() {
    return {
      amount: 0.0,
      percentage: 0,
      selectedYear: '',
      selectedPointOfLaw: '',
      selectedIndustry: '',
      selectedSubIndustry: '',
      industryOptions: [],
      subIndustryOptions: [],
      pointOfLaws: [],
      caseTable: [],
      pageNo: 1,
      dataSize: 50,
    }
  },
  computed: {
    ...mapGetters("dashboard", ["cases"]),
  },
  watch: {
    cases: {
      handler: function (data, prevData) {
        this.handleDataUpdate();
        if (data.length > 0 && prevData.length == 0) {
          setTimeout(() => {
            this.dataMounted();
          }, 100);
        }
      },
      deep: true,
    },
  },
  unmounted() {
    if (this.industryIndustriesRef) {
      this.industryIndustriesRef.destroy();
      this.industryIndustriesRef = null;
    }
    if (this.industrySubIndustriesRef) {
      this.industrySubIndustriesRef.destroy();
      this.industrySubIndustriesRef = null;
    }
    if (this.pointOfLawRef) {
      this.pointOfLawRef.destroy();
      this.pointOfLawRef = null;
    }
  },
  mounted() {
    this.handleDataUpdate();
    setTimeout(() => {
      this.dataMounted();
    }, 100);
  },
  methods: {
    ...mapMutations("dashboard", ["updateCaseDetailFilters"]),
    isNewCase,
    nFormatter,
    generateRandom,
    getFormatedDate,
    capitalizeFirstLetter,
    dataMounted() {
      setTimeout(() => {
        if (document.getElementById("prediction_industry")) {
          if (this.industryIndustriesRef) {
            this.industryIndustriesRef.destroy();
            this.industryIndustriesRef = null;
          }
          var industry = document.getElementById("prediction_industry");
          this.industryIndustriesRef = new Choices(industry, {
            choices: this.industryOptions,
            allowHTML: true,
            shouldSort: false,
            itemSelectText: "",
            searchEnabled: true,
            searchPlaceholderValue: "Search Industries",
            classNames: {
              containerOuter: "choices custom_menu_list"
            }
          });
          if (!this.selectedIndustry) {
            this.industryIndustriesRef.setChoiceByValue("");
          }
        }
        if (document.getElementById("prediction_sub_industry")) {
          if (this.industrySubIndustriesRef) {
            this.industrySubIndustriesRef.destroy();
            this.industrySubIndustriesRef = null;
          }
          var subIndustry = document.getElementById("prediction_sub_industry");
          this.industrySubIndustriesRef = new Choices(subIndustry, {
            choices: this.subIndustryOptions,
            allowHTML: true,
            shouldSort: false,
            itemSelectText: "",
            searchEnabled: true,
            searchPlaceholderValue: "Search Sub-Industries",
            classNames: {
              containerOuter: "choices custom_menu_list"
            }
          });
          if (!this.selectedSubIndustry) {
            this.industrySubIndustriesRef.setChoiceByValue("");
          }
        }
        if (document.getElementById("prediction_point_of_law")) {
          if (this.pointOfLawRef) {
            this.pointOfLawRef.destroy();
            this.pointOfLawRef = null;
          }
          var caseType = document.getElementById("prediction_point_of_law");
          this.pointOfLawRef = new Choices(caseType, {
            choices: this.pointOfLaws,
            allowHTML: true,
            shouldSort: false,
            itemSelectText: "",
            searchEnabled: true,
            searchPlaceholderValue: "Search Point of Law",
            classNames: {
              containerOuter: "choices custom_menu_list"
            }
          });
          if (!this.selectedPointOfLaw) {
            this.pointOfLawRef.setChoiceByValue("");
          }
        }
        this.updateCasesTableData();
        this.predictCase();
      }, 500);
    },
    getGroupWiseData(casesData) {
      let indGrp = {}, subIndGrp = {};
      casesData.forEach(cs => {
        (cs.industryData || []).forEach(ind => {
          if (ind?.GroupDesc) {
            // eslint-disable-next-line no-prototype-builtins
            if (indGrp[(ind.GroupDesc)]) {
              indGrp[ind.GroupDesc].push(cs);
            } else {
              indGrp[ind.GroupDesc] = [cs];
            }
          }
          if (ind?.CodeDesc) {
            // eslint-disable-next-line no-prototype-builtins
            if (subIndGrp[(ind.CodeDesc)]) {
              subIndGrp[ind.CodeDesc].push(cs);
            } else {
              subIndGrp[ind.CodeDesc] = [cs];
            }
          }
        })
      });
      return { indGrp, subIndGrp };
    },
    handleDataUpdate() {
      const { indGrp = {}, subIndGrp = {} } = this.getGroupWiseData(this.cases);
      this.industryOptions = _.sortBy(_.uniqBy(Object.entries(indGrp).map(([key]) => ({
        label: key,
        value: key,
      })), 'value'), 'value');
      this.industryOptions.splice(0, 0, { label: "Select Industry", value: "" });

      this.subIndustryOptions = _.sortBy(_.uniqBy(Object.entries(subIndGrp).map(([key]) => ({
        label: key,
        value: key,
      })), 'value'), 'value');

      this.subIndustryOptions.splice(0, 0, { label: "Select Sub-Industries", value: "" });
      this.pointOfLaws = _.uniq(this.cases.flatMap(c => c.category || []).filter(j => j)).map(i => ({
        label: i,
        value: i,
      }));
      this.pointOfLaws.splice(0, 0, { label: "All", value: "" });
    },
    onIndustryChange(value) {
      let newOptions = [];
      const { subIndGrp } = this.getGroupWiseData(this.cases.filter(c => (c.industryData || []).some(ind => value == '' || ((ind?.GroupDesc || '') == value))));
      newOptions = _.sortBy(_.uniqBy(Object.entries(subIndGrp).map(([key]) => ({
        label: key,
        value: key,
      })), 'value'), 'value');
      newOptions.splice(0, 0, { label: "Select Sub-Industries", value: "" });
      if (this.industrySubIndustriesRef) {
        this.industrySubIndustriesRef.setChoices(newOptions, "value", "label", true);
        this.industrySubIndustriesRef.setChoiceByValue("");
      }
      if (this.pointOfLawRef) {
        this.pointOfLawRef.setChoiceByValue("");
      }
      this.selectedIndustry = value;
      this.selectedSubIndustry = '';
      this.selectedPointOfLaw = '';
      setTimeout(() => {
        this.updateCasesTableData();
        this.predictCase();
      }, 400);
    },
    onSubIndustryChange(value) {
      this.selectedSubIndustry = value;
      setTimeout(() => {
        this.updateCasesTableData();
        this.predictCase();
      }, 400);
    },
    onPointOfLawChange(value) {
      this.selectedPointOfLaw = value;
      setTimeout(() => {
        this.updateCasesTableData();
        this.predictCase();
      }, 400);
    },
    getFilteredCases() {
      return this.cases.filter((myCase) =>
        (!this.selectedIndustry || (this.selectedIndustry && (myCase.industryData || []).map(ind => (ind?.GroupDesc || '')).includes(this.selectedIndustry)))
        && (!this.selectedSubIndustry || (this.selectedSubIndustry && (myCase.industryData || []).map(ind => (ind?.CodeDesc || '')).includes(this.selectedSubIndustry)))
        && (!this.selectedPointOfLaw || (this.selectedPointOfLaw && (myCase.category || []).includes(this.selectedPointOfLaw)))
      );
    },
    async predictCase() {
      const filteredCases = this.getFilteredCases();
      const claimantWonCases = filteredCases.filter(c => (c?.result_aggregated || []).map(r => String(r).toLowerCase()).includes('claimant won'));
      const totalClaimantWonCases = claimantWonCases.length;
      const totalFilteredCases = filteredCases.length;
      const casesAmounts = filteredCases.flatMap(c => (c?.orderedPayment_aggregated || []).map(n => Number(getNumberOnly(n))));
      let amount = 0;
      if (casesAmounts.length > 1) {
        amount = Number(Number(casesAmounts.reduce((a, b) => a + (!isNaN(b) ? b : 0), 0) / totalClaimantWonCases).toFixed(2));
      } else if (casesAmounts.length == 1) {
        amount = casesAmounts[0];
      }

      this.amount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(isNaN(amount) ? 0 : amount);
      this.percentage = Number(Number((totalClaimantWonCases * 100) / totalFilteredCases).toFixed(2));
    },
    getTribunalAward(orderedPayment) {
      const casesAmounts = orderedPayment.map(n => Number(getNumberOnly(n)));
      let amount = 0;
      if (casesAmounts.length > 1) {
        amount = Number(Number(casesAmounts.reduce((a, b) => a + (!isNaN(b) ? b : 0), 0)).toFixed(2));
      } else if (casesAmounts.length == 1) {
        amount = casesAmounts[0];
      }
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(isNaN(amount) ? 0 : amount);
    },
    onPageNoChange(page) {
      this.pageNo = page;
    },
    updateCasesTableData() {
      const cases = this.getFilteredCases();
      this.caseTable = _.orderBy(cases, [(cs => cs.caseDate ? moment(cs.caseDate, 'YYYY-MM-DD HH:mm').toDate() : 0)], ['desc']);
    },
    activeCaseDetailsTab(caseId) {
      if (!caseId) return null;
      this.updateCaseDetailFilters({ selectedCase: caseId });
      setTimeout(() => {
        const get = document.getElementsByClassName("case-details-tab")[0];
        if (get) {
          setTimeout(() => {
            get.click();
            get.classList.add('active');
          }, 200);
        }
      }, 500);
    },
  },
}
</script>

<style scoped>
select {
  border-radius: 0px;
  color: #fff;
  font-size: 13px;
  padding: 0.3rem 0.5rem;
}

.choices[data-type*=select-one]::after {
  color: #fff !important;
}

.custom_menu_list .choices__list--dropdown .choices__list {
  max-height: 180px;
}

.bg-orange {
  background-color: #FFBE0B;
}

.bg-purple {
  background-color: #702962;
}

th {
  font-size: 0.9rem;
  text-wrap: wrap;
  text-align: center;
  padding: 0.75rem 0.25rem !important;
}

td.value {
  font-size: 0.8rem;
  line-height: 1.2rem;
  vertical-align: middle;
  text-align: center;
  text-wrap: wrap;
}

@media only screen and (max-width : 768px) {
  table {
    table-layout: auto !important;
  }

  .th {
    padding: 0.75rem 1.25rem !important;
  }
}
</style>