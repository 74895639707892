import { createApp } from "vue";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import { Chart, LinearScale } from "chart.js";
import { createAuth0 } from "@auth0/auth0-vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueAwesomePaginate from "vue-awesome-paginate";
import { Flow, SankeyController } from "chartjs-chart-sankey";

import App from "./App.vue";
import ArgonDashboard from "./argon-dashboard";

import store from "./store";
import router from "./router";

import "leaflet/dist/leaflet.css";
import '@vuepic/vue-datepicker/dist/main.css';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "vue-awesome-paginate/dist/style.css";

Chart.register(LinearScale, SankeyController, Flow);

const appInstance = createApp(App);
appInstance
  .use(store)
  .use(router)
  .use(VueTilt)
  .use(VueSweetalert2)
  .use(VueDatePicker)
  .use(ArgonDashboard)
  .use(VueAwesomePaginate)
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENTID,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    })
  )
  .mount("#app");
