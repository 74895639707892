<template>
  <div v-if="fetchingData" class="empty-view">
    <ArgonLoader />
  </div>
  <div v-else class="container-fluid pb-0 mt-3">
    <div id="v-pills-tabContent" class="tab-content">
      <div id="cases" :class="['tab-pane fade', activeTab == 'cases' && 'show active']" role="tabpanel"
        aria-labelledby="cases">
        <Cases />
      </div>
      <div id="case_details" :class="['tab-pane fade', activeTab == 'case_details' && 'show active']" role="tabpanel"
        aria-labelledby="case_details">
        <CaseDetails />
      </div>
      <!-- <div id="case_connections" :class="['tab-pane fade', activeTab == 'case_connections' && 'show active']"
        role="tabpanel" aria-labelledby="case_connections">
        <CaseConnections />
      </div> -->
      <!-- <div id="company" :class="['tab-pane fade', activeTab == 'company' && 'show active']" role="tabpanel"
        aria-labelledby="company">
        <Company />
      </div> -->
      <!-- <div id="gpg" :class="['tab-pane fade', activeTab == 'gpg' && 'show active']" role="tabpanel"
        aria-labelledby="gpg">
        <GPG />
      </div> -->
      <!-- <div id="industry" :class="['tab-pane fade', activeTab == 'industry' && 'show active']" role="tabpanel"
        aria-labelledby="industry">
        <Industry />
      </div> -->
      <div id="prediction" :class="['tab-pane fade position-relative', activeTab == 'prediction' && 'show active']"
        role="tabpanel" aria-labelledby="prediction">
        <Prediction />
      </div>
      <!-- <div id="trials" :class="['tab-pane fade position-relative', activeTab == 'trials' && 'show active']"
        role="tabpanel" aria-labelledby="trials">
        <Trials />
      </div> -->
      <!-- <div id="legislation" :class="['tab-pane fade position-relative', activeTab == 'legislation' && 'show active']"
        role="tabpanel" aria-labelledby="legislation">
        <LawsAndRegulations />
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toRaw } from 'vue';
import moment from "moment";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { mapMutations, mapGetters } from "vuex";

// import GPG from "./Tabs/GPG.vue";
import Cases from "./Tabs/Cases.vue";
// import Trials from "./Tabs/Trials.vue";
// import Company from "./Tabs/Company.vue";
// import Industry from "./Tabs/Industry.vue";
import Prediction from "./Tabs/Prediction.vue";
import CaseDetails from "./Tabs/CaseDetails.vue";
import ArgonLoader from "@/components/ArgonLoader.vue";
// import CaseConnections from "./Tabs/CaseConnections.vue";
// import LawsAndRegulations from "./Tabs/LawsAndRegulations.vue";

import { updateMovingTab } from '@/assets/js/nav-pills';
import { getValidDate } from "@/utils/utils.js";
import api from "@/utils/api.js";

export default {
  name: "MyDashboard",
  components: {
    // GPG,
    Cases,
    // Trials,
    // Company,
    // Industry,
    Prediction,
    ArgonLoader,
    CaseDetails,
    // CaseConnections,
    // LawsAndRegulations,
  },
  data() {
    return {
      headerHeight: 0,
      isSidebarNarrow: false,
      isAuthenticated: toRaw(this.$auth0.isAuthenticated).value,
    }
  },
  computed: {
    ...mapGetters("dashboard", ["fetchingData", "cases", "activeTab"]),
  },
  watch: {
    activeTab: function (newTab) {
      if (window.screen.width > 1024) {
        if (!this.isSidebarNarrow && newTab === 'prediction') {
          this.setSidebarNarrow();
          updateMovingTab();
        } else if (this.isSidebarNarrow && newTab !== 'prediction') {
          this.setSidebarRegular();
          updateMovingTab();
        }
      }
    },
  },
  unmounted() {
    this.updateActiveTab('cases');
  },
  mounted() {
    const elem = document.getElementById("navbarBlur");
    if (elem) {
      const rect = elem.getBoundingClientRect();
      this.headerHeight = rect.height;
    }
  },
  created() {
    if (!this.cases.length) {
      if (this.isAuthenticated) {
        this.getData();
      } else {
        this.updateFetchingData(false);
      }
      this.updateActiveTab('cases');
    }
  },
  methods: {
    ...mapMutations("dashboard", [
      "updateFetchingData", "updateCases", 'updateCompany', 'updateIndustry', "updateCities",
      "updateActiveTab", 'updateTrialData', 'updateCasesFooterData', 'updateCompanyFooterData',
      "updateIndustryFooterData", "updateCaseDetailFilters", "updateLawAndRegs", "updateLawAndRegsFooterData",
      // 'updateGPGData', 'updateGPGFooterData', 'updateGPGCompanies'
    ]),
    getCasesData(data = [], key = '', value = '') {
      return data.length && key && value ? data.find(d => d[key] && value && String(d[key]).toLowerCase() == String(value).toLowerCase()) : {};
    },
    async getData() {
      try {
        const [
          allCasesResponse,
          allCompniesResponse,
          allIndustryResponse,
          allCitiesResponse,
          allLawAndRegulations,
          // allGPGCompaniesResponse,
          // allGPGResponse,
          // allGPGIndustryResponse,
          // allTrialsResponse,
        ] = await Promise.all([
          api.get("/demo/decisions"),
          api.get("/demo/companies?companyIDs=ALL"),
          api.get("/demo/industries"),
          api.get("/demo/cities"),
          api.get("/demo/laws"),
          // api.get("/demo/gpgCompanies"),
          // api.get("/demo/gpg"),
          // api.get("/demo/gpgIndustry"),
          // api.get("/trials3"),
        ]);
        const allCasesData = (allCasesResponse?.data?.data || []);
        const casesHeaderData = (allCasesResponse?.data?.header || {});
        const allIndustryData = sortBy((allIndustryResponse?.data?.data || []), 'GroupDesc').map(ind => ({
          ...ind,
          // gpgIndustriesData: (allGPGIndustryResponse?.data?.data || []).filter(gpgInd => ind?.Code && gpgInd?.SicCodes && Number(gpgInd?.SicCodes || '0') == (ind?.Code || '')),
        }));
        const industryHeaderData = (allIndustryResponse?.data?.header || {});
        const lawsAndRegsHeaderData = (allLawAndRegulations?.data?.header || {});
        const allCityData = (allCitiesResponse?.data?.data || []);
        const allCompanyData = (allCompniesResponse?.data?.data || []).map(c => {
          const industries = (c.sic || []).map(sicCode => {
            const code = Number(sicCode);
            const industryData = this.getCasesData(allIndustryData, 'Code', code) || {};
            return industryData;
          });
          return {
            ...c,
            industries
          };
        });
        const companyHeaderData = (allCompniesResponse?.data?.header || {});
        // const gpgHeaderData = (allGPGCompaniesResponse?.data?.header || {});

        const allNewCasesData = allCasesData.map(c => {
          const companiesId = (c?.companyRef || []).map(c => c);
          const caseRespondants = (c?.respondent || []).filter(r => r).map(rs => String(rs).toLowerCase());
          c['caseDate'] = c['caseDate'] ? getValidDate(c['caseDate']) : '';
          c['companyData'] = allCompanyData.filter(c => companiesId.includes((c?.id || '')));
          c['industryData'] = (c?.industryRef || []).map(code => this.getCasesData(allIndustryData, 'Code', code || ''));
          c['cityData'] = (c?.city_aggregated || []).map(ct => this.getCasesData(allCityData, 'City', ct || '') || {});
          c['caseHistory'] = orderBy(c?.caseHistory || [], [(h) => h.date ? moment(h.date, 'YYYY-MM-DD HH:mm').toDate() : 0], ['asc']);
          c['respondantData'] = allCompanyData.filter(cmp => cmp?.respondent && caseRespondants.length && caseRespondants.includes(cmp.respondent));
          return c;
        });

        const allLawAndRegs = (allLawAndRegulations?.data?.data || []).map(c => ({
          ...c,
          junctionDecisionsData: uniqBy(allNewCasesData.filter(cs => (c.junctionDecisions || []).includes(Number(cs.id))), 'id')
        }));

        // const allGPGCompanyData = sortBy((allGPGCompaniesResponse?.data?.data || []).filter(c => c.CompanyNumber).map(c => ({
        //   ...c,
        //   industries: (c?.SicCodes || '').split(",\n").map(code => (allIndustryData.find(ind => ind.Code == Number(code)) || {}))
        // })), 'CurrentName');

        this.updateCases(allNewCasesData);
        if (allNewCasesData.length > 0) {
          this.updateCaseDetailFilters({ selectedCase: (allNewCasesData.find(d => d.id) || { id: '' }).id });
        }
        this.updateCasesFooterData(casesHeaderData);
        this.updateCompany(allCompanyData);
        this.updateCompanyFooterData(companyHeaderData);
        this.updateIndustry(allIndustryData);
        this.updateIndustryFooterData(industryHeaderData);
        this.updateCities(allCityData);
        this.updateLawAndRegsFooterData(lawsAndRegsHeaderData);
        this.updateLawAndRegs(allLawAndRegs);
        // this.updateGPGData(allGPGData);
        // this.updateGPGFooterData(gpgHeaderData);
        // this.updateGPGCompanies(allGPGCompanyData);
        // this.updateTrialData(allTrialsResponse?.data?.data || []);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.data && (error.response.data.message || error.response.data.error)) {
            alert(error.response.data.message || error.response.data.error);
          } else if (error.response.status == 429 && error.response.statusText) {
            alert(error.response.statusText);
          } else {
            alert('Something went wrong. Please load data after sometime.');
          }
        }
      } finally {
        setTimeout(() => {
          this.updateFetchingData(false);
        }, 2000);
      }
    },
    setActiveTab(tab) {
      this.updateActiveTab(tab);
      setTimeout(() => {
        document.querySelector('main').scrollTo({ top: 0, left: 0, behavior: 'auto' });
      });
    },
    setSidebarNarrow() {
      this.isSidebarNarrow = true;
      const asideElement = document.getElementById('sidenav-main');
      if (asideElement) {
        asideElement.setAttribute('style', 'max-width:12rem !important');
      }
      const mainContentView = document.getElementById('main-content-view');
      if (mainContentView) {
        mainContentView.setAttribute('style', 'margin-left:13.5rem !important');
      }
      const tabElement = document.getElementById(`${this.activeTab}_tab`);
      if (tabElement) {
        setTimeout(() => {
          tabElement.click();
          tabElement.classList.add('active');
        }, 200);
      }
    },
    setSidebarRegular() {
      this.isSidebarNarrow = false;
      const asideElement = document.getElementById('sidenav-main');
      if (asideElement) {
        asideElement.setAttribute('style', 'max-width:18rem !important');
      }
      const mainContentView = document.getElementById('main-content-view');
      if (mainContentView) {
        mainContentView.setAttribute('style', 'margin-left:19.5rem !important');
      }
      const tabElement = document.getElementById(`${this.activeTab}_tab`);
      if (tabElement) {
        setTimeout(() => {
          tabElement.click();
          tabElement.classList.add('active');
        }, 200);
      }
    },
  },
}
</script>

<style scoped>
.empty-view {
  height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>