<template>
  <div class="container-fluid">
    <div>
      <div data-custom-class="body">
        <div>
          <strong>
            <span style="font-size: 26px;">
              <span data-custom-class="title">
                PRIVACY NOTICE
              </span>
            </span>
          </strong>
        </div>
        <br>
        <div>
          <span style="color: rgb(127, 127, 127);">
            <strong>
              <span style="font-size: 15px;">
                <span data-custom-class="subtitle">Last updated January 22, 2024
                </span>
              </span>
            </strong>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span data-custom-class="body_text">This privacy notice for
                Kort Ltd (doing business as Kort AI) ('<strong>we</strong>', '<strong>us</strong>', or
                '<strong>our</strong>'),
              </span>
              <span data-custom-class="body_text">describes
                how and why we might collect, store, use, and/or share ('<strong>process</strong>') your information when
                you use our services ('<strong>Services</strong>'), such as when you:
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">Visit our website at <span style="color: rgb(0, 58, 250);">
                    <a href="http://www.kort.ai" target="_blank" data-custom-class="link">http://www.kort.ai</a>,
                  </span>
                  <span style="font-size: 15px;">
                    <span style="color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        <span style="font-size: 15px;">
                          <span style="color: rgb(89, 89, 89);">
                            or any website of ours that links to this privacy
                            notice
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or
              events
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span style="color: rgb(127, 127, 127);">
          <span data-custom-class="body_text">
            <strong>Questions or concerns? </strong>Reading this privacy notice will help
            you understand your privacy rights and choices. If you do not agree with our policies and practices, please
            do not use our Services. If you still have any questions or concerns,
            please contact us at privacy@kort.ai.
          </span>
        </span>
      </span>
    </div>
    <br>
    <br>
    <div style="line-height: 1.5;">
      <strong>
        <span style="font-size: 15px;">
          <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
        </span>
      </strong>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>
            <em>
              This summary provides key points from our privacy notice, but you can find out more details about any of
              these
              topics by clicking the link following each key point or by using our
            </em>
          </strong>
        </span>
      </span>
      <a data-custom-class="link" href="#toc">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text">
            <strong>
              <em>table of contents</em>
            </strong>
          </span>
        </span>
      </a>
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>
            <em> below to find the section you are looking for.</em>
          </strong>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may
          process
          personal information depending on how you interact with us and the Services, the choices you make, and the
          products and features you use. Learn more about
        </span>
      </span>
      <a data-custom-class="link" href="#personalinfo">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text">personal information you disclose to us</span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>Do we process any sensitive personal information?</strong>
          We do not process sensitive personal information.
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>Do we receive any information from third parties?</strong>
          We do not receive any information from third parties.
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>How do we process your information?</strong> We process your information to provide, improve, and
          administer our
          Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
          your information for other purposes with your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about
        </span>
      </span>
      <a data-custom-class="link" href="#infouse">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text">
            how we process your information
          </span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>In what situations and with which types of parties do we share personal information?</strong>
          We may share information in specific situations and with specific categories of third parties. Learn more about
        </span>
      </span>
      <a data-custom-class="link" href="#whoshare">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text"> when and with whom we share your personal information</span>
        </span>
      </a>
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">.
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>How do we keep your information safe?</strong> We have organisational and technical processes and
          procedures in place to protect your personal
          information. However, no electronic transmission over the internet or information storage technology can be
          guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorised third parties will not be able to
          defeat our security and improperly collect, access, steal, or modify your information. Learn more about
        </span>
      </span>
      <a data-custom-class="link" href="#infosafe">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text"> how we keep your information safe</span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy
          law may mean
          you have certain rights regarding your personal information. Learn more about
        </span>
      </span>
      <a data-custom-class="link" href="#privacyrights">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text"> your privacy rights</span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a
        </span>
      </span>
      <a data-custom-class="link" href="https://app.termly.io/notify/cb527e6a-ba86-4ef2-98eb-8a7dd61cf2c5"
        rel="noopener noreferrer" target="_blank">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text">data subject access request</span>
        </span>
      </a>
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">Want to learn more about what we do with any information we collect?</span>
      </span>
      <a data-custom-class="link" href="#toc">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">
          <span data-custom-class="body_text"> Review the privacy notice in full</span>
        </span>
      </a>
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">.</span>
      </span>
    </div>
    <br>
    <br>
    <div id="toc" style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span style="color: rgb(127, 127, 127);">
          <span style="color: rgb(0, 0, 0);">
            <strong>
              <span data-custom-class="heading_1">
                TABLE OF CONTENTS
              </span>
            </strong>
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#infocollect">
          <span style="color: rgb(0, 58, 250);">1. WHAT INFORMATION DO WE COLLECT?</span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#infouse">
          <span style="color: rgb(0, 58, 250);">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#legalbases">
          <span style="color: rgb(0, 58, 250);">3.
            <span style="font-size: 15px;">
              <span style="color: rgb(0, 58, 250);"> WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?</span>
            </span>
          </span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span style="color: rgb(0, 58, 250);">
          <a data-custom-class="link" href="#whoshare">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </span>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#cookies">
          <span style="color: rgb(0, 58, 250);">
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#sociallogins">
          <span style="color: rgb(0, 58, 250);">
            <span style="color: rgb(0, 58, 250);">
              <span style="color: rgb(0, 58, 250);">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
            </span>
          </span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#inforetain">
          <span style="color: rgb(0, 58, 250);">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#infosafe">
          <span style="color: rgb(0, 58, 250);">
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#infominors">
          <span style="color: rgb(0, 58, 250);">9. DO WE COLLECT INFORMATION FROM MINORS?</span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span style="color: rgb(0, 58, 250);">
          <a data-custom-class="link" href="#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </span>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#DNT">
          <span style="color: rgb(0, 58, 250);">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <a data-custom-class="link" href="#policyupdates">
          <span style="color: rgb(0, 58, 250);">12. DO WE MAKE UPDATES TO THIS NOTICE?</span>
        </a>
      </span>
    </div>
    <div style="line-height: 1.5;">
      <a data-custom-class="link" href="#contact">
        <span style="color: rgb(0, 58, 250); font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
      </a>
    </div>
    <div style="line-height: 1.5;">
      <a data-custom-class="link" href="#request">
        <span style="color: rgb(0, 58, 250);">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?</span>
      </a>
    </div>
    <br>
    <br>
    <div id="infocollect" style="line-height: 1.5;">
      <span style="color: rgb(0, 0, 0);">
        <span style="color: rgb(0, 0, 0); font-size: 15px;">
          <span style="font-size: 15px; color: rgb(0, 0, 0);">
            <span style="font-size: 15px; color: rgb(0, 0, 0);">
              <span id="control" style="color: rgb(0, 0, 0);">
                <strong>
                  <span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span>
                </strong>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <br>
    <div id="personalinfo" style="line-height: 1.5;">
      <span data-custom-class="heading_2" style="color: rgb(0, 0, 0);">
        <span style="font-size: 15px;">
          <strong>Personal information you disclose to us</strong>
        </span>
      </span>
    </div>
    <div>
      <br>
      <div style="line-height: 1.5;">
        <span style="color: rgb(127, 127, 127);">
          <span style="color: rgb(89, 89, 89); font-size: 15px;">
            <span data-custom-class="body_text">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span data-custom-class="body_text">
                    <strong>
                      <em></em>
                    </strong>
                    <em>We collect personal information that you provide to us.</em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you
            register on the Services,
          </span>
          <span style="font-size: 15px;">
            <span data-custom-class="body_text"> express an interest in obtaining information about us or
              our products and Services, when you participate in activities on the Services, or otherwise when you
              contact us.
            </span>
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the
            context of
            your interactions with us and the Services, the choices you make, and the products and features you use. The
            personal information we collect may include the following:
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  names
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  phone numbers
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  email addresses
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  mailing addresses
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  job titles
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  usernames
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  passwords
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  contact preferences
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  contact or authentication data
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  billing addresses
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                <span data-custom-class="body_text">
                  debit/credit card numbers
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div id="sensitiveinfo" style="line-height: 1.5;">
      <span style="font-size: 15px;">
        <span data-custom-class="body_text">
          <strong>Sensitive Information.</strong>
          We do not process sensitive information.
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases,
            such
            as your
            payment instrument number, and the security code associated with your payment instrument. All payment data
            is stored by Stripe.
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  You may find their privacy notice link(s) here:
                  <span style="color: rgb(0, 58, 250);">
                    <a href="https://stripe.com/gb/privacy" target="_blank" data-custom-class="link">
                      https://stripe.com/gb/privacy
                    </a>.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text"><strong>Social Media Login Data. </strong>We may provide you with the option
            to register with us using your existing social
            media account details, like your Facebook, Twitter, or other social media account. If you choose to register
            in this way, we will collect the information described in the section called '<span style="font-size: 15px;">
              <span data-custom-class="body_text">
                <span style="font-size: 15px;">
                  <span style="color: rgb(0, 58, 250);">
                    <a data-custom-class="link" href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
                  </span>
                </span>
              </span>
            </span>' below.
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            All personal information that you provide to us must be true, complete, and accurate, and you must notify us
            of
            any changes to such personal information.
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span data-custom-class="heading_2" style="color: rgb(0, 0, 0);">
        <span style="font-size: 15px;">
          <strong>Information automatically collected</strong>
        </span>
      </span>
    </div>
    <div>
      <br>
      <div style="line-height: 1.5;">
        <span style="color: rgb(127, 127, 127);">
          <span style="color: rgb(89, 89, 89); font-size: 15px;">
            <span data-custom-class="body_text">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span data-custom-class="body_text">
                    <strong><em>In Short:</em></strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span data-custom-class="body_text">
                    <em>
                      Some information — such as your Internet Protocol (IP) address and/or browser and device
                      characteristics — is collected automatically when you visit our Services.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            We automatically collect certain information when you visit, use, or navigate the Services. This information
            does not reveal your
            specific identity (like your name or contact information) but may include device and usage information, such
            as your IP address, browser and device characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when you use our Services, and other
            technical information. This information is primarily needed to maintain the security and operation of our
            Services, and for our internal analytics and reporting purposes.
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            Like many businesses, we also collect information through cookies and similar technologies.
          </span>
        </span>
      </span>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">The information we collect includes:</span>
        </span>
      </span>
    </div>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance
              information our
              servers automatically collect when you access or use our Services and which we record in log files.
              Depending on how you interact with us, this log data may include your IP address, device information,
              browser type, and settings and information about your activity in the Services (such as the date/time stamps
              associated with your usage, pages and
              files viewed, searches, and other actions you take such as which features you use), device event
              information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware
              settings).
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <em>Device Data.</em>
              We collect device data such as information about your computer, phone, tablet, or other device you use to
              access the Services. Depending on the device used, this device data may include information such as your
              IP address (or proxy server), device and application identification numbers, location, browser type,
              hardware model, Internet service provider and/or mobile carrier, operating system, and system
              configuration information.
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <em>Location Data.</em>
              We collect location data such as information about your device's location, which can be either precise or
              imprecise. How much information we collect depends on the type and settings of the device you use to
              access the Services. For example, we may use GPS and other technologies to collect geolocation data that
              tells us your current location (based on your IP address). You can opt out of allowing us to collect this
              information either by refusing access to the information or by disabling your Location setting on your
              device. However, if you choose to opt out, you may not be able to use certain aspects of the
              Services.
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div id="infouse" style="line-height: 1.5;">
      <span style="color: rgb(127, 127, 127);">
        <span style="color: rgb(89, 89, 89); font-size: 15px;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span id="control" style="color: rgb(0, 0, 0);">
                <strong>
                  <span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
                </strong>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div>
      <br>
      <div style="line-height: 1.5;">
        <span style="color: rgb(127, 127, 127);">
          <span style="color: rgb(89, 89, 89); font-size: 15px;">
            <span data-custom-class="body_text">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short: </em>
                    </strong>
                    <em>
                      We process your information to provide, improve, and administer our
                      Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                      also process your information for other purposes with your
                      consent.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <br>
    <div style="line-height: 1.5;">
      <span style="font-size: 15px; color: rgb(89, 89, 89);">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span data-custom-class="body_text">
            <strong>
              We process your personal information for a variety of reasons, depending on how you interact with our
              Services, including:
            </strong>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <strong>To facilitate account creation and authentication and otherwise manage user accounts. </strong>
              We may process your information so you can create and log in to your account, as well as keep your account
              in working order.
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <strong>To deliver and facilitate delivery of services to the user. </strong>
              We may process your information to provide you with the requested service.
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <strong>To respond to user inquiries/offer support to users. </strong>
              We may process your information to respond to your inquiries and solve any potential issues you might
              have with the requested service.
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              <strong>To send administrative information to you. </strong>
              We may process your information to send you details about our products and services, changes to our
              terms and policies, and other similar information.
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span data-custom-class="body_text">
          <span style="font-size: 15px;">
            <strong>
              To fulfil and manage your orders.
            </strong> We may process your information to fulfil and manage your orders, payments, returns, and
            exchanges made through the
            Services.
          </span>
        </span>
      </li>
    </ul>
    <ul>
      <li style="line-height: 1.5;">
        <span data-custom-class="body_text">
          <span style="font-size: 15px;">
            <strong>To save or protect an individual's vital interest. </strong>
            We may process your information when necessary to save or protect an individual's vital
            interest, such as to prevent harm.
          </span>
        </span>
      </li>
    </ul>
    <div style="line-height: 1.5;">
      <div style="line-height: 1.5;">
        <div style="line-height: 1.5;">
          <div style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <br>
              <div id="legalbases" style="line-height: 1.5;">
                <strong>
                  <span style="font-size: 15px;">
                    <span data-custom-class="heading_1">
                      3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                    </span>
                  </span>
                </strong>
              </div>
              <br>
              <div style="line-height: 1.5;">
                <em>
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>In Short: </strong>
                      We only process your personal information when we believe it is necessary and we have a
                      valid legal reason (i.e. legal basis)
                      to do so under applicable law, like with your consent, to comply with
                      laws, to provide you with services to enter into or
                      fulfil our
                      contractual obligations, to protect your rights, or to
                      fulfil our
                      legitimate business interests.
                    </span>
                  </span>
                </em>
              </div>
              <br>
              <div style="line-height: 1.5;">
                <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                    The General Data Protection Regulation
                    (GDPR) and UK GDPR require us to explain the valid legal bases we rely on
                    in order to process your personal information. As such, we may rely on the
                    following legal bases to process your personal information:
                  </span>
                </span>
              </div>
              <ul>
                <li style="line-height: 1.5;">
                  <span style="font-size: 15px;">
                    <span data-custom-class="body_text">
                      <strong>Consent. </strong>
                      We may process your information if you have given us permission (i.e. consent) to use your personal
                      information for a specific purpose. You can withdraw your consent at any
                      time. Learn more about {{ " " }}
                    </span>
                  </span>
                  <a data-custom-class="link" href="#withdrawconsent">
                    <span style="color: rgb(0, 58, 250); font-size: 15px;">
                      <span data-custom-class="body_text">withdrawing your consent</span>
                    </span>
                  </a>
                  <span data-custom-class="body_text">.</span>
                </li>
              </ul>
              <ul>
                <li style="line-height: 1.5;">
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      <strong>Performance of a Contract.</strong> We
                      may process your personal information when we believe it is necessary to fulfil
                      our contractual obligations to you, including providing our Services or
                      at your request prior to entering into a contract with
                      you.
                    </span>
                  </span>
                </li>
              </ul>
              <ul>
                <li style="line-height: 1.5;">
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      <strong>Legal Obligations.</strong> We may
                      process your information where we believe it is necessary for compliance
                      with our legal obligations, such as to cooperate with a law enforcement
                      body or regulatory agency, exercise or defend our legal rights, or
                      disclose your information as evidence in litigation in which we are
                      involved.
                      <br>
                    </span>
                  </span>
                </li>
              </ul>
              <ul>
                <li style="line-height: 1.5;">
                  <span data-custom-class="body_text">
                    <span style="font-size: 15px;">
                      <strong>Vital Interests.</strong> We may
                      process your information where we believe it is necessary to protect
                      your vital interests or the vital interests of a third party, such as
                      situations involving potential threats to the safety of any
                      person.
                    </span>
                  </span>
                </li>
              </ul>
              <br>
              <div id="whoshare" style="line-height: 1.5;">
                <span style="color: rgb(127, 127, 127);">
                  <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span id="control" style="color: rgb(0, 0, 0);">
                          <strong>
                            <span data-custom-class="heading_1">
                              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <br>
              <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span data-custom-class="body_text">
                      <strong><em>In Short:</em></strong>
                      <em> We
                        may share information in specific situations described in this section
                        and/or with the following categories of third parties.
                      </em>
                    </span>
                  </span>
                </span>
              </div>
              <br>
              <div style="line-height: 1.5;">
                <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                    <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share
                    your data with
                    third-party vendors, service providers, contractors, or agents ('<strong>third parties</strong>') who
                    perform services for
                    us or on our behalf
                    and require access to such information to do that work. We
                    have contracts in place with our third
                    parties, which are designed to help safeguard your personal information.
                    This means that they cannot do anything with your personal information
                    unless we have instructed them to do it. They will also not share your
                    personal information with any organisation apart from us. They also commit
                    to protect the data they hold on our behalf and to retain it for the
                    period we instruct. The categories of third parties we may share
                    personal information with are as follows:
                  </span>
                </span>
              </div>
              <ul>
                <li style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        Social Networks
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <ul>
                <li style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        Ad Networks
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <ul>
                <li style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        Cloud Computing Services
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <ul>
                <li style="line-height: 1.5;">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                      <span data-custom-class="body_text">
                        Affiliate Marketing Programs
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </span>
          </div>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;">
            <span data-custom-class="body_text">
              We also may need to share your personal information in the following situations:
            </span>
          </span>
        </div>
        <ul>
          <li style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                <strong>Business Transfers.</strong> We
                may share or transfer your information in connection with, or during
                negotiations of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business to another
                company.
              </span>
            </span>
          </li>
        </ul>
        <ul>
          <li style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                <strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain
                Google Maps Platform APIs (e.g. Google Maps API, Places API).
              </span>
            </span>
          </li>
        </ul>
        <br>
        <div id="cookies" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                <strong>
                  <em>In Short:</em>
                </strong>
                <em> We may use cookies and other
                  tracking technologies to collect and store your
                  information.
                </em>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                We may use cookies and similar
                tracking technologies (like web beacons and pixels) to access or
                store information. Specific information about how we use such
                technologies and how you can refuse certain cookies is set out
                in our Cookie Notice.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div id="sociallogins" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                <strong>
                  <em>In Short: </em>
                </strong>
                <em>If you choose to register or log in
                  to our Services using a social media account, we may have
                  access to certain information about
                  you.
                </em>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                Our Services offer you the ability
                to register and log in using your third-party social media
                account details (like your Facebook or Twitter logins). Where
                you choose to do this, we will receive certain profile
                information about you from your social media provider. The
                profile information we receive may vary depending on the social
                media provider concerned, but will often include your name,
                email address, friends list, and profile picture, as well as
                other information you choose to make public on such a social
                media platform.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                We will use the information we
                receive only for the purposes that are described in this privacy
                notice or that are otherwise made clear to you on the relevant
                Services. Please note that we do not control, and are not
                responsible for, other uses of your personal information by your
                third-party social media provider. We recommend that you review
                their privacy notice to understand how they collect, use, and
                share your personal information, and how you can set your
                privacy preferences on their sites and apps.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div id="inforetain" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                <strong>
                  <em>In Short: </em>
                </strong>
                <em>
                  We keep your information for as
                  long as necessary to fulfil
                  the purposes outlined in this
                  privacy notice unless otherwise required by
                  law.
                </em>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                We will only keep your personal
                information for as long as it is necessary for the purposes set
                out in this privacy notice, unless a longer retention period is
                required or permitted by law (such as tax, accounting, or other
                legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than three (3) months past the
                termination of the user's account.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                When we have no ongoing legitimate
                business need to process your personal information, we will
                either delete or anonymise
                such information, or, if this is
                not possible (for example, because your personal information has
                been stored in backup archives), then we will securely store
                your personal information and isolate it from any further
                processing until deletion is possible.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div id="infosafe" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                <strong>
                  <em>In Short: </em>
                </strong>
                <em>
                  We aim to protect your personal
                  information through a system of organisational and
                  technical security measures.
                </em>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                We have implemented appropriate
                and reasonable technical and
                organisational security measures
                designed to protect the security of any personal information we
                process. However, despite our safeguards and efforts to secure
                your information, no electronic transmission over the Internet
                or information storage technology can be guaranteed to be 100%
                secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other
                unauthorised third parties will
                not be able to defeat our security and improperly collect,
                access, steal, or modify your information. Although we will do
                our best to protect your personal information, transmission of
                personal information to and from our Services is at your own
                risk. You should only access the Services within a secure
                environment.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div id="infominors" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        9. DO WE COLLECT INFORMATION FROM MINORS?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                <strong><em>In Short: </em></strong>
                <em>
                  We do not knowingly collect data
                  from or market to children
                  under 18 years of age.
                </em>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                We do not knowingly solicit data
                from or market to children under 18 years of age. By using the
                Services, you represent that you are at least 18 or that you are
                the parent or guardian of such a minor and consent to such minor
                dependent's use of the Services. If we learn that personal
                information from users less than 18 years of age has been
                collected, we will deactivate the account and take reasonable
                measures to promptly delete such data from our records. If you
                become aware of any data we may have collected from children
                under age 18, please contact us at <span style="color: rgb(89, 89, 89); font-size: 15px;">
                  <span data-custom-class="body_text">info@kort.ai.</span>
                </span>
              </span>
            </span>
          </span>
          <br>
          <div id="privacyrights" style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
              <span style="color: rgb(89, 89, 89); font-size: 15px;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" style="color: rgb(0, 0, 0);">
                      <strong>
                        <span data-custom-class="heading_1">
                          10. WHAT ARE YOUR PRIVACY RIGHTS?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  <strong>
                    <em>In Short: </em>
                  </strong>
                  <em>
                    In some regions, such as the European Economic Area
                    (EEA), United Kingdom (UK), and Switzerland,
                    you have rights that allow
                    you greater access to and control over your personal
                    information. You may review, change, or
                    terminate your account at any time.
                  </em>
                </span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  In some regions (like the EEA, UK, and Switzerland), you have
                  certain rights under
                  applicable data protection laws. These may include the right (i)
                  to request access and obtain a copy of your personal
                  information, (ii) to request rectification or erasure; (iii) to
                  restrict the processing of your personal information; (iv) if
                  applicable, to data portability; and (v) not to be subject to
                  automated decision-making. In certain circumstances, you may
                  also have the right to object to the processing of your personal
                  information. You can make such a request by contacting us by
                  using the contact details provided in the section '
                </span>
              </span>
            </span>
            <a data-custom-class="link" href="#contact">
              <span style="font-size: 15px; color: rgb(0, 58, 250);">
                <span style="font-size: 15px; color: rgb(0, 58, 250);">
                  <span data-custom-class="body_text">
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </span>
              </span>
            </a>
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  ' below.
                </span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  We will consider and act upon any
                  request in accordance with applicable data protection laws.
                </span>
              </span>
            </span>
          </div>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);"> </span>
          </div>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  If you are located in the EEA or
                  UK and you believe we are unlawfully processing your personal
                  information, you also have the right to complain to your <span style="font-size: 15px;">
                    <span style="color: rgb(0, 58, 250);">
                      <span data-custom-class="body_text">
                        <span style="color: rgb(0, 58, 250);">
                          <span data-custom-class="body_text">
                            <a data-custom-class="link"
                              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                              rel="noopener noreferrer" target="_blank">
                              <span style="font-size: 15px;">
                                Member State data protection authority
                              </span>
                            </a>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  or </span>
              </span>
            </span>
            <a data-custom-class="link"
              href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
              rel="noopener noreferrer" target="_blank">
              <span style="font-size: 15px; color: rgb(0, 58, 250);">
                <span style="font-size: 15px; color: rgb(0, 58, 250);">
                  <span data-custom-class="body_text">UK data protection authority</span>
                </span>
              </span>
            </a>
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">.</span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  If you are located in Switzerland, you may contact the <span style="font-size: 15px;">
                    <span style="color: rgb(0, 58, 250);">
                      <span data-custom-class="body_text">
                        <span style="color: rgb(0, 58, 250);">
                          <span data-custom-class="body_text">
                            <span style="color: rgb(0, 58, 250); font-size: 15px;">
                              <a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                rel="noopener noreferrer" target="_blank">
                                Federal Data Protection and Information Commissioner
                              </a>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  .</span>
              </span>
            </span>
          </div>
          <br>
          <div id="withdrawconsent" style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  <strong>
                    <u>Withdrawing your consent:</u>
                  </strong> If we are relying on your consent to
                  process your personal information,
                  you have the right to withdraw
                  your consent at any time. You can withdraw your consent at any
                  time by contacting us by using the contact details provided in
                  the section '
                </span>
              </span>
            </span>
            <a data-custom-class="link" href="#contact">
              <span style="font-size: 15px; color: rgb(0, 58, 250);">
                <span style="font-size: 15px; color: rgb(0, 58, 250);">
                  <span data-custom-class="body_text">
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </span>
              </span>
            </a>
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  ' below or updating your preferences.
                </span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                However, please note that this will
                not affect the lawfulness of the processing before its withdrawal
                nor, will it affect the processing of
                your personal information conducted in reliance on lawful
                processing grounds other than consent.
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                <strong>
                  <u>Opting out of marketing and promotional communications:</u>
                </strong>
                <strong>
                </strong> You can unsubscribe from our marketing and promotional communications at any time by
                clicking on the unsubscribe link in the emails that we send, or by contacting us using the
                details provided in the section '
              </span>
            </span>
            <a data-custom-class="link" href="#contact">
              <span style="color: rgb(0, 58, 250); font-size: 15px;">
                <span data-custom-class="body_text">
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </span>
            </a>
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                '
                below. You will then be removed from the marketing lists. However,
                we may still communicate with you — for example, to send you
                service-related messages that are necessary for the administration
                and use of your account, to respond to service requests, or for
                other non-marketing purposes.
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <span data-custom-class="heading_2">
                <strong>Account Information</strong>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                If you would at any time like to review
                or change the information in your account or terminate your
                account, you can:
              </span>
            </span>
          </div>
          <ul>
            <li style="line-height: 1.5;">
              <span data-custom-class="body_text">
                <span style="font-size: 15px;">
                  Contact us using the contact information provided.
                </span>
              </span>
            </li>
          </ul>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px;">
              <span data-custom-class="body_text">
                Upon your request to terminate your
                account, we will deactivate or delete your account and information
                from our active databases. However, we may retain some information
                in our files to prevent fraud, troubleshoot problems, assist with
                any investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  <strong>
                    <u>Cookies and similar technologies:</u>
                  </strong> Most Web browsers are set to
                  accept cookies by default. If you prefer, you can usually choose
                  to set your browser to remove cookies and to reject cookies. If
                  you choose to remove cookies or reject cookies, this could
                  affect certain features or services of our Services.
                </span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span data-custom-class="body_text">
              <span style="font-size: 15px;">
                If you have questions or comments about
                your privacy rights, you may email us at privacy@kort.ai.
              </span>
            </span>
          </div>
          <br>
          <div id="DNT" style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
              <span style="color: rgb(89, 89, 89); font-size: 15px;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span id="control" style="color: rgb(0, 0, 0);">
                      <strong>
                        <span data-custom-class="heading_1">
                          11. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <br>
          <div style="line-height: 1.5;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span data-custom-class="body_text">
                  Most web browsers and some mobile
                  operating systems and mobile applications include a Do-Not-Track
                  ('DNT') feature or setting you can activate
                  to signal your privacy preference not to have data about your
                  online browsing activities monitored and collected. At this
                  stage no uniform technology standard for
                  recognising and implementing DNT signals has
                  been finalised. As such, we do not currently
                  respond to DNT browser signals or any other mechanism that
                  automatically communicates your choice not to be tracked online.
                  If a standard for online tracking is adopted that we must follow
                  in the future, we will inform you about that practice in a
                  revised version of this privacy notice.
                </span>
              </span>
            </span>
          </div>
        </div>
        <br>
        <div id="policyupdates" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        12. DO WE MAKE UPDATES TO THIS NOTICE?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                <em>
                  <strong>In Short: </strong>
                  Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </em>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                We may update this privacy notice
                from time to time. The updated version will be indicated by an
                updated 'Revised' date and the updated version will be
                effective as soon as it is accessible. If we make material
                changes to this privacy notice, we may notify you either by
                prominently posting a notice of such changes or by directly
                sending you a notification. We encourage you to review this
                privacy notice frequently to be informed of how we are
                protecting your information.
              </span>
            </span>
          </span>
        </div>
        <br>
        <div id="contact" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);">
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span id="control" style="color: rgb(0, 0, 0);">
                    <strong>
                      <span data-custom-class="heading_1">
                        13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span data-custom-class="body_text">
                If you have questions or comments
                about this notice, you may <span style="color: rgb(89, 89, 89); font-size: 15px;">
                </span>
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                  <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span data-custom-class="body_text">
                      contact us by post at:
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <br>
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              Kort Ltd
            </span>
          </span>
        </div>
        <div style="line-height: 1.5; font-size: 15px;">
          <span data-custom-class="body_text">
            1st Floor 124 Cleveland Street
          </span>
        </div>
        <div style="line-height: 1.5; font-size: 15px;">
          <span data-custom-class="body_text">
            London, United Kingdom W1T 6PG
          </span>
        </div>
        <div style="line-height: 1.5; font-size: 15px;">
          <span data-custom-class="body_text">
            United Kingdom
          </span>
        </div>
      </div>
      <br>
      <div id="request" style="line-height: 1.5;">
        <span style="color: rgb(127, 127, 127);">
          <span style="color: rgb(89, 89, 89); font-size: 15px;">
            <span style="font-size: 15px; color: rgb(89, 89, 89);">
              <span style="font-size: 15px; color: rgb(89, 89, 89);">
                <span id="control" style="color: rgb(0, 0, 0);">
                  <strong>
                    <span data-custom-class="heading_1">
                      14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <br>
      <div style="line-height: 1.5;">
        <span style="font-size: 15px; color: rgb(89, 89, 89);">
          <span style="font-size: 15px; color: rgb(89, 89, 89);">
            <span data-custom-class="body_text">
              Based on the applicable laws
              of your country, you may have the right to request access to the
              personal information we collect from you, change that information, or delete it.
              To request to review, update, or delete your personal information, please fill out and submit a
            </span>
            <span style="color: rgb(0, 58, 250);">
              <span data-custom-class="body_text">
                <span style="color: rgb(0, 58, 250); font-size: 15px;">
                  <a data-custom-class="link" href="https://app.termly.io/notify/cb527e6a-ba86-4ef2-98eb-8a7dd61cf2c5"
                    rel="noopener noreferrer" target="_blank">
                    data subject access request
                  </a>
                </span>
              </span>
            </span>
          </span>
        </span>
        <span data-custom-class="body_text">.</span>
      </div>
    </div>
    <div style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">
      This privacy policy was created using Termly's <a style="color: rgb(48, 48, 241) !important;"
        href="https://termly.io/products/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a>.
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "PrivacyPolicy",
  created() {
    this.toggleConfigButton(true);
    this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.toggleConfigButton(false);
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations("theme", ["toggleDefaultLayout", "toggleConfigButton"]),
  },
};
</script>
<style scoped>
[data-custom-class='body'],
[data-custom-class='body'] * {
  background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
  color: #3030F1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

ul {
  list-style-type: square;
}

ul>li>ul {
  list-style-type: circle;
}

ul>li>ul>li>ul {
  list-style-type: square;
}

ol li {
  font-family: Arial;
}
</style>
