<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-100 sidenav-collapse-main">
    <ul v-if="!fetchingData && showSidebarContent" class="navbar-nav sidenav-list pb-4 h-100 overflow-hidden">
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="cases_dates" :expanded="true" nav-text="Date">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12 date-range-picker-container">
                  <VueDatePicker range multi-calendars :hide-navigation="['time']" :teleport="!isMobile()"
                    :teleport-center="isMobile()" :format="casesDatePickerformat"
                    :model-value="casesFilters.selectedCasesDate" input-class-name="custome_calendar_picker_input"
                    @update:model-value="onCasesDateChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industries" :expanded="true" nav-text="Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industries" class="form-select choices__inner border-0" name="industries"
                    @change="onIndustryChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="subIndustries" :expanded="true"
          class-name="choices__inner border-0" nav-text="Sub-Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="sub-industries" class="form-select choices__inner border-0" name="sub-industries"
                    @change="onSubIndustryChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="company" :expanded="true"
          class-name="choices__inner border-0" nav-text="Company">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="company" class="form-select choices__inner border-0" name="company"
                    @change="onCompanyChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="cities" :expanded="true"
          class-name="choices__inner border-0" nav-text="Cities">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="cities" class="form-select choices__inner border-0" name="cities"
                    @change="onCityChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="result" :expanded="true"
          class-name="choices__inner border-0" nav-text="Result">
          <template #nav-header>
            <img :src="require('@/assets/img/ai.png')" height="13px" class="ms-2" />
          </template>
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="result" class="form-select choices__inner border-0" name="result"
                    @change="onResultChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="point_of_law" :expanded="true"
          class-name="choices__inner border-0" nav-text="Point of Law">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="point_of_law" class="form-select choices__inner border-0" name="point_of_law"
                    @change="onPointOfLawChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="judge" :expanded="true"
          class-name="choices__inner border-0" nav-text="Judge">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="judge" class="form-select choices__inner border-0" name="judge"
                    @change="onJudgeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'cases'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="case_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Case Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="case_type" class="form-select choices__inner border-0" name="case_type"
                    @change="onCaseTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'case_details'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="case_details_case" :expanded="true"
          class-name="choices__inner border-0" nav-text="Cases">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="case_details_case" class="form-select choices__inner border-0" name="case_details_case"
                    @change="onCaseDetailsCaseChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li v-if="activeTab === 'company'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="company_company" :expanded="true"
          class-name="choices__inner border-0" nav-text="Company">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="company_company" class="form-select choices__inner border-0" name="company_company"
                    @change="onCompanyCompanyChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'gpg'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="gpg_company" :expanded="true"
          class-name="choices__inner border-0" nav-text="Company">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="gpg_company" class="form-select choices__inner border-0" name="gpg_company"
                    @change="onGPGCompanyChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'gpg'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="gpg_competitor" :expanded="true" nav-text="Competitor">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="gpg_competitor" multiple class="form-select choices__inner border-0" name="gpg_competitor"
                    @change="onGPGCompetitorChange($event.target)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_years" :expanded="true"
          class-name="choices__inner border-0" nav-text="Years">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_years" class="form-select choices__inner border-0" name="industry_years"
                    @change="onIndustryYearChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_industries" :expanded="true"
          class-name="choices__inner border-0" nav-text="Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_industries" class="form-select choices__inner border-0"
                    name="industry_industries" @change="onIndustryIndustriesChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_subIndustries" :expanded="true"
          class-name="choices__inner border-0" nav-text="Sub-Industries">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_sub-industries" class="form-select choices__inner border-0"
                    name="industry_sub-industries" @change="onIndustrySubIndustryChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'industry'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="industry_case_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Case Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="industry_case_type" class="form-select choices__inner border-0" name="industry_case_type"
                    @change="onIndustryCaseTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'legislation'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="tribunal_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Tribunal Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="tribunal_type" class="form-select choices__inner border-0" name="tribunal_type"
                    @change="onLNRTribunalTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse :always-expanded="true" collapse-ref="law_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Law Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="law_type" class="form-select choices__inner border-0" name="law_type"
                    @change="onLNRLawTypeChange($event.target.value)" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_date" :expanded="true" nav-text="Date">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12 date-range-picker-container">
                  <VueDatePicker range multi-calendars :hide-navigation="['time']" :teleport="!isMobile()"
                    :teleport-center="isMobile()" :format="trialDatePickerformat"
                    :model-value="trialFilters.selectedTrialDate" input-class-name="custome_calendar_picker_input"
                    @update:model-value="onTrialsDateChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_type" :expanded="true"
          class-name="choices__inner border-0" nav-text="Type">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_type" class="form-select choices__inner border-0" name="trials_type"
                    @change="onTrialsTypeChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_hearing_time" :expanded="true"
          class-name="choices__inner border-0" nav-text="Hearing Time">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_hearing_time" class="form-select choices__inner border-0"
                    name="trials_hearing_time" @change="onTrialsHearingTimeChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_venue" :expanded="true"
          class-name="choices__inner border-0" nav-text="Venue">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_venue" class="form-select choices__inner border-0" name="trials_venue"
                    @change="onTrialsVenueChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_caseno" :expanded="true"
          class-name="choices__inner border-0" nav-text="Case No.">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_caseno" class="form-select choices__inner border-0" name="trials_caseno"
                    @change="onTrialsCaseNoChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_point_of_law" :expanded="true"
          class-name="choices__inner border-0" nav-text="Point of Law">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_point_of_law" class="form-select choices__inner border-0"
                    name="trials_point_of_law" @change="onTrialsPointOfLawChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="activeTab === 'trials'" class="nav-item">
        <sidenav-collapse :always-expanded="true" collapse-ref="trials_claimant" :expanded="true"
          class-name="choices__inner border-0" nav-text="Claimant">
          <template #list>
            <ul class="nav ms-4">
              <div class="row w-100">
                <div class="col-12">
                  <select id="trials_claimant" class="form-select choices__inner border-0" name="trials_claimant"
                    @change="onTrialsClaimantChange" />
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <li v-if="activeTab !== 'prediction' && activeTab !== 'case_connections'"
        class="nav-item d-flex justify-content-center">
        <argon-button color="primary" class="mt-3 py-2" @click.prevent="resetTabFilters">
          {{ isResetting ? 'Resetting...' : 'Reset Filters' }}
        </argon-button>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
// import uniqBy from "lodash/uniqBy";
import * as Choices from "choices.js";
import { mapGetters, mapMutations } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';

import ArgonButton from "@/components/ArgonButton.vue";
import SidenavCollapse from "./SidenavCollapse.vue";

import { isMobile, isDateBetween } from '@/utils/utils.js';
import eventBus from '../../utils/eventBus';

export default {
  name: "SidenavList",
  components: {
    ArgonButton,
    VueDatePicker,
    SidenavCollapse,
  },
  data() {
    return {
      searchQuery: '',
      showSidebarContent: true,
      isResetting: false,
      industry_selectedSubIndustry: '',
    }
  },
  computed: {
    ...mapGetters("dashboard", [
      "fetchingData", "cases", "casesFilters", "activeTab", 'companies', 'industries', "gpgData",
      "cities", "companyFilters", "gpgFilters", "industryFilters", "caseDetailFilters", "lnrFilters",
      "gpgCompanies", "lawAndRegs", // "trialsData", "trialFilters",
    ]),
  },
  watch: {
    '$route': {
      handler: function (newRoute) {
        const { name } = newRoute;
        this.showSidebarContent = name == "MyDashboard";
        if (this.showSidebarContent) {
          this.dataMounted();
        }
      },
      deep: true,
    },
    fetchingData: {
      handler: function (flag) {
        if (!flag) {
          setTimeout(() => {
            this.dataMounted();
          }, 100);
        }
      },
    },
    cases: {
      handler: function () {
        this.handleCasesDataChange();
      },
      deep: true,
    },
    companies: {
      handler: function () {
        this.handleCompanyDataUpdate();
      },
      deep: true,
    },
    gpgCompanies: {
      handler: function (newData, oldData) {
        if (JSON.stringify(newData) != JSON.stringify(oldData)) {
          this.handleMainGPGCompanyDataUpdate();
        }
      },
      deep: true,
    },
    gpgFilters: {
      handler: function (newFilter, oldFilter) {
        if (newFilter.selectedCompany !== oldFilter.selectedCompany) {
          this.handleGPGCompanyDataUpdate(true);
        }
      },
      deep: true,
    },
    activeTab: function () {
      setTimeout(() => {
        this.dataMounted();
      }, 500);
    },
  },
  beforeUnmount() {
    eventBus.all().clear();
  },
  mounted() {
    if (this.cases.length) {
      setTimeout(() => {
        this.dataMounted();
      }, 500);
    }
  },
  created() {
    eventBus.on('company-change', (companyId) => {
      if (companyId && this.company_companyRef) {
        this.company_companyRef.setChoiceByValue(companyId)
      }
    });
    eventBus.on('on-search-cases', (query) => {
      this.searchQuery = query;
      setTimeout(() => {
        this.dataMounted();
      }, 500);
    });
  },
  methods: {
    ...mapMutations("dashboard", [
      "updateCasesFilters",
      // "updateTrialFilter",
      "updateGPGFilters",
      "updateCompanyFilters",
      "updateIndustryFilters",
      "updateCaseDetailFilters",
      "updateLNRFilters"
    ]),
    isMobile,
    dataMounted() {
      setTimeout(() => {
        try {
          if (this.activeTab === "cases") {
            if (document.getElementById("industries")) {
              var industries = document.getElementById("industries");
              if (this.industriesRef) {
                this.industriesRef.destroy();
                this.industriesRef = null;
              }
              const indData = this.getCasesIndustryWise().flatMap(c => c.industryData).filter(ind => !!ind?.GroupDesc);
              let industryChoices = _.sortBy(_.uniqBy(indData.map(i => ({
                label: i.GroupDesc,
                value: i.GroupDesc,
              })), 'value'), 'value');
              industryChoices.splice(0, 0, { label: "All", value: "" });
              this.industriesRef = new Choices(industries, {
                choices: industryChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Industries",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.industriesRef.setChoiceByValue(this.casesFilters.selectedIndustry);
              this.handleChoiseListener(industries, industryChoices, 'industriesRef');
            }
            if (document.getElementById("sub-industries")) {
              if (this.subIndustryRef) {
                this.subIndustryRef.destroy();
                this.subIndustryRef = null;
              }
              var subIndustries = document.getElementById("sub-industries");
              const subInd = this.getCasesSubIndustryWise().flatMap(c => c.industryData).filter(ind => !!ind?.CodeDesc);
              let subIndustriesChoices = _.sortBy(_.uniqBy(subInd.map(i => ({
                label: i.CodeDesc,
                value: i.CodeDesc,
              })), 'value'), 'value');
              subIndustriesChoices.splice(0, 0, { label: "All", value: "" });
              this.subIndustryRef = new Choices(subIndustries, {
                choices: subIndustriesChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Sub-Industries",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.subIndustryRef.setChoiceByValue(this.casesFilters.selectedSubIndustry);
              this.handleChoiseListener(subIndustries, subIndustriesChoices, 'subIndustryRef');
            }
            if (document.getElementById("company")) {
              var company = document.getElementById("company");
              if (this.companyRef) {
                this.companyRef.destroy();
                this.companyRef = null;
              }
              const companies = this.getCasesCompanyWise().filter(c => (c?.companyData || []).length > 0);
              let companyChoices = _.sortBy(_.uniqBy(companies.flatMap(c => (c?.companyData || [])).map(i => ({
                label: (i?.name || ''),
                value: (i?.name || ''),
              })), 'value'), 'value');
              companyChoices.splice(0, 0, { label: "All", value: "" });
              this.companyRef = new Choices(company, {
                choices: companyChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Companies",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.companyRef.setChoiceByValue(this.casesFilters.selectedCompany);
              this.handleChoiseListener(company, companyChoices, 'companyRef');
            }
            if (document.getElementById("cities")) {
              if (this.citiesRef) {
                this.citiesRef.destroy();
                this.citiesRef = null;
              }
              const cCities = this.getCasesCityWise().filter(c => (c?.city_aggregated || []).length > 0);
              let cityChoices = _.sortBy(_.uniqBy(cCities.flatMap(c => (c?.city_aggregated || [])).map(i => ({
                label: i,
                value: i,
              })), 'value'), 'value');
              cityChoices.splice(0, 0, { label: "All", value: "" });
              var cities = document.getElementById("cities");
              this.citiesRef = new Choices(cities, {
                choices: cityChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Cities",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.citiesRef.setChoiceByValue(this.casesFilters.selectedCity);
              this.handleChoiseListener(cities, cityChoices, 'citiesRef');
            }
            if (document.getElementById("result")) {
              if (this.resultRef) {
                this.resultRef.destroy();
                this.resultRef = null;
              }
              var result = document.getElementById("result");
              const results = this.getCasesResultWise().filter(c => (c?.result_aggregated || [])).flatMap(c => (c?.result_aggregated || []));
              let resultChoices = _.sortBy(_.uniqBy(results.map(i => ({
                label: i,
                value: i,
              })), 'value'), 'value');
              resultChoices.splice(0, 0, { label: "All", value: "" });
              this.resultRef = new Choices(result, {
                choices: resultChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Results",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.resultRef.setChoiceByValue(this.casesFilters.selectedResult);
              this.handleChoiseListener(result, resultChoices, 'resultRef');
            }
            if (document.getElementById("point_of_law")) {
              var pointOfLawCodeElement = document.getElementById("point_of_law");
              if (this.pointOfLawRef) {
                this.pointOfLawRef.destroy();
                this.pointOfLawRef = null;
              }
              const jurCodes = _.uniq(this.getCasesPointOfLawWise().flatMap(c => c.category || []));
              let pointOfLawChoices = _.sortBy(jurCodes.map(i => ({
                label: i,
                value: i,
              })), 'value');
              pointOfLawChoices.splice(0, 0, { label: "All", value: "" });
              this.pointOfLawRef = new Choices(pointOfLawCodeElement, {
                choices: pointOfLawChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Point of Law",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.pointOfLawRef.setChoiceByValue(this.casesFilters.selectedPointOfLaw);
              this.handleChoiseListener(pointOfLawCodeElement, pointOfLawChoices, 'pointOfLawRef');
            }
            if (document.getElementById("judge")) {
              if (this.judgeRef) {
                this.judgeRef.destroy();
                this.judgeRef = null;
              }
              const judges = this.getCasesJudgeWise().flatMap(c => (c.heardBefore_aggregated || []));
              let judgeChoices = _.sortBy(_.uniqBy(judges.map(j => ({
                label: j,
                value: j,
              })), 'value'), 'value');
              judgeChoices.splice(0, 0, { label: "All", value: "" });
              var judgeElement = document.getElementById("judge");
              this.judgeRef = new Choices(judgeElement, {
                choices: judgeChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Judge",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.judgeRef.setChoiceByValue(this.casesFilters.selectedJudge);
              this.handleChoiseListener(judgeElement, judgeChoices, 'judgeRef');
            }
            if (document.getElementById("case_type")) {
              if (this.caseTypeRef) {
                this.caseTypeRef.destroy();
                this.caseTypeRef = null;
              }

              let caseTypeChoices = _.sortBy(_.uniqBy(this.getCasesCaseTypeWise().map(i => ({
                label: i.caseType,
                value: i.caseType,
              })), 'value'), 'value');
              caseTypeChoices.splice(0, 0, { label: "All", value: "" });
              var caseTypeElement = document.getElementById("case_type");
              this.caseTypeRef = new Choices(caseTypeElement, {
                choices: caseTypeChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchPlaceholderValue: "Search Case Type",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              this.caseTypeRef.setChoiceByValue(this.casesFilters.selectedCaseType);
              this.handleChoiseListener(caseTypeElement, caseTypeChoices, 'caseTypeRef');
            }
          } else if (this.activeTab === "case_details") {
            if (document.getElementById("case_details_case")) {
              if (this.case_details_caseRef) {
                this.case_details_caseRef.destroy();
                this.case_details_caseRef = null;
              }
              var caseElement = document.getElementById("case_details_case");
              const casesChoices = this.cases.filter(c => c.id).map(c => ({ label: c.caseName, value: c.id }));
              this.case_details_caseRef = new Choices(caseElement, {
                choices: casesChoices,
                allowHTML: true,
                shouldSort: false,
                itemSelectText: "",
                searchEnabled: true,
                searchFields: ['label'],
                searchPlaceholderValue: "Search Cases",
                classNames: {
                  containerOuter: "choices custom_menu_list"
                }
              });
              if (this.caseDetailFilters.selectedCase && this.case_details_caseRef) {
                this.case_details_caseRef.setChoiceByValue(this.caseDetailFilters.selectedCase);
              }
              this.handleChoiseListener(caseElement, casesChoices, 'case_details_caseRef');
            }
          }
          // else if (this.activeTab === "company") {
          //   if (document.getElementById("company_company")) {
          //     if (this.company_companyRef) {
          //       this.company_companyRef.destroy();
          //       this.company_companyRef = null;
          //     }
          //     var companyElement = document.getElementById("company_company");
          //     const companyChoices = this.companies.map(c => ({ label: c.name, value: String(c.id || '') }));
          //     this.company_companyRef = new Choices(companyElement, {
          //       choices: companyChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Companies",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.companyFilters.selectedCompany && this.company_companyRef) {
          //       this.company_companyRef.setChoiceByValue(this.companyFilters.selectedCompany)
          //     }
          //     this.handleChoiseListener(companyElement, companyChoices, 'company_companyRef');
          //   }
          // } else if (this.activeTab === "gpg") {
          //   if (document.getElementById("gpg_company")) {
          //     if (this.gpg_companyRef) {
          //       this.gpg_companyRef.destroy();
          //       this.gpg_companyRef = null;
          //     }
          //     var gpgCompanyElement = document.getElementById("gpg_company");
          //     const gpgCompanyChoices = _.uniqBy(this.gpgCompanies.map(c => ({
          //       label: c?.CurrentName || c?.EmployerName || '',
          //       value: c?.CompanyNumber || '',
          //     })), 'value');
          //     this.gpg_companyRef = new Choices(gpgCompanyElement, {
          //       choices: gpgCompanyChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Companies",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.gpgFilters.selectedCompany && this.gpg_companyRef) {
          //       this.gpg_companyRef.setChoiceByValue(this.gpgFilters.selectedCompany)
          //     }
          //     this.handleChoiseListener(gpgCompanyElement, gpgCompanyChoices, 'gpg_companyRef');
          //   }
          //   if (document.getElementById("gpg_competitor")) {
          //     if (this.gpg_competitorRef) {
          //       this.gpg_competitorRef.destroy();
          //       this.gpg_competitorRef = null;
          //     }
          //     var gpgCompetitorElement = document.getElementById("gpg_competitor");
          //     this.gpg_competitorRef = new Choices(gpgCompetitorElement, {
          //       choices: [],
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       removeItemButton: true,
          //       searchPlaceholderValue: "Search Competitors",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     setTimeout(() => {
          //       if (this.gpgFilters.selectedCompany) {
          //         this.handleGPGCompanyDataUpdate(false);
          //       }
          //     }, 500);
          //   }
          // } else if (this.activeTab === "industry") {
          //   if (document.getElementById("industry_years")) {
          //     if (this.industry_yearsRef) {
          //       this.industry_yearsRef.destroy();
          //       this.industry_yearsRef = null;
          //     }
          //     let yearChoices = _.orderBy(
          //       _.uniqBy(
          //         this.cases.filter(c => c.caseDate && moment(c.caseDate, 'YYYY-MM-DD HH:mm').isValid())
          //           .map(cs => ({
          //             label: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
          //             value: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
          //           })), 'value'),
          //       'value',
          //       'desc'
          //     );
          //     yearChoices.splice(0, 0, { label: "Select Year", value: "" });
          //     var industryYears = document.getElementById("industry_years");
          //     this.industry_yearsRef = new Choices(industryYears, {
          //       choices: yearChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Years",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.industryFilters.selectedYear) {
          //       this.industry_yearsRef.setChoiceByValue(this.industryFilters.selectedYear);
          //     }
          //     this.handleChoiseListener(industryYears, yearChoices, 'industry_yearsRef');
          //   }
          //   if (document.getElementById("industry_industries")) {
          //     if (this.industry_industriesRef) {
          //       this.industry_industriesRef.destroy();
          //       this.industry_industriesRef = null;
          //     }
          //     const industriesData = this.getIndustry_Industries().flatMap(ind => ind.industryData).filter(f => !!f?.GroupDesc);
          //     let indChoices = _.sortBy(_.uniqBy(industriesData.map(cs => ({
          //       label: cs.GroupDesc,
          //       value: cs.GroupDesc,
          //     })), 'value'), 'value');
          //     indChoices.splice(0, 0, { label: "Select Industries", value: "" });
          //     var industryIndustries = document.getElementById("industry_industries");
          //     this.industry_industriesRef = new Choices(industryIndustries, {
          //       choices: indChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Industries",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.industryFilters.selectedIndustry) {
          //       this.industry_industriesRef.setChoiceByValue(this.industryFilters.selectedIndustry);
          //     }
          //     this.handleChoiseListener(industryIndustries, indChoices, 'industry_industriesRef');
          //   }
          //   if (document.getElementById("industry_sub-industries")) {
          //     if (this.industry_sub_industriesRef) {
          //       this.industry_sub_industriesRef.destroy();
          //       this.industry_sub_industriesRef = null;
          //     }
          //     const subIndustriesData = this.getIndustry_SubIndustries().flatMap(ind => ind.industryData).filter(f => !!f?.CodeDesc);
          //     let subIndChoices = _.sortBy(_.uniqBy(subIndustriesData.map(cs => ({
          //       label: cs.CodeDesc,
          //       value: cs.CodeDesc,
          //     })), 'value'), 'value');
          //     subIndChoices.splice(0, 0, { label: "Select Sub-Industries", value: "" })
          //     const industrySubIndustries = document.getElementById("industry_sub-industries");
          //     this.industry_sub_industriesRef = new Choices(industrySubIndustries, {
          //       choices: subIndChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Sub-Industries",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.industryFilters.selectedSubIndustry) {
          //       this.industry_sub_industriesRef.setChoiceByValue(this.industryFilters.selectedSubIndustry);
          //     }
          //     this.handleChoiseListener(industrySubIndustries, subIndChoices, 'industry_sub_industriesRef');
          //   }
          //   if (document.getElementById("industry_case_type")) {
          //     if (this.industry_caseTypeRef) {
          //       this.industry_caseTypeRef.destroy();
          //       this.industry_caseTypeRef = null;
          //     }
          //     let caseTypeChoices = _.sortBy(_.uniqBy(this.getIndustry_CaseTypes().map(cs => ({
          //       label: cs.caseType,
          //       value: cs.caseType,
          //     })), 'value'), 'value');
          //     caseTypeChoices.splice(0, 0, { label: "Select Case Type", value: "" });
          //     const industrySubIndustries = document.getElementById("industry_case_type");
          //     this.industry_caseTypeRef = new Choices(industrySubIndustries, {
          //       choices: caseTypeChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Case Type",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.industryFilters.selectedCaseType) {
          //       this.industry_caseTypeRef.setChoiceByValue(this.industryFilters.selectedCaseType);
          //     }
          //     this.handleChoiseListener(industrySubIndustries, caseTypeChoices, 'industry_caseTypeRef');
          //   }
          // } else if (this.activeTab === 'legislation') {
          //   if (document.getElementById("tribunal_type")) {
          //     if (this.tribunalTypeRef) {
          //       this.tribunalTypeRef.destroy();
          //       this.tribunalTypeRef = null;
          //     }

          //     let tribunalTypeChoices = _.sortBy(_.uniqBy(this.cases.map(i => ({
          //       label: i.caseType,
          //       value: i.caseType,
          //     })), 'value'), 'value');
          //     tribunalTypeChoices.splice(0, 0, { label: "All", value: "" });
          //     var tribunalTypeElement = document.getElementById("tribunal_type");
          //     this.tribunalTypeRef = new Choices(tribunalTypeElement, {
          //       choices: tribunalTypeChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Tribunal Type",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     this.tribunalTypeRef.setChoiceByValue(this.lnrFilters.selectedTribunalType);
          //     this.handleChoiseListener(tribunalTypeElement, tribunalTypeChoices, 'tribunalTypeRef');
          //   }
          //   if (document.getElementById("law_type")) {
          //     if (this.lawTypeRef) {
          //       this.lawTypeRef.destroy();
          //       this.lawTypeRef = null;
          //     }

          //     let lawTypeChoices = _.sortBy(_.uniqBy(this.lawAndRegs.filter(l => (l?.lawType || '').trim()).map(i => ({
          //       label: i.lawType,
          //       value: i.lawType,
          //     })), 'value'), 'value');
          //     lawTypeChoices.splice(0, 0, { label: "All", value: "" });
          //     var lawTypeElement = document.getElementById("law_type");
          //     this.lawTypeRef = new Choices(lawTypeElement, {
          //       choices: lawTypeChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Law Type",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     this.lawTypeRef.setChoiceByValue(this.lnrFilters.selectedlawType);
          //     this.handleChoiseListener(lawTypeElement, lawTypeChoices, 'lawTypeRef');
          //   }
          // } else if (this.activeTab === "trials") {
          //   if (document.getElementById("trials_type")) {
          //     if (this.trialsTypeRef) {
          //       this.trialsTypeRef.destroy();
          //       this.trialsTypeRef = null;
          //     }
          //     var trialsType = document.getElementById("trials_type");
          //     let trialsTypeChoices = _.sortBy(_.uniqBy(
          //       this.getTrialsTypeWise().filter(d => d.trial_type).map(td => ({ label: td.trial_type, value: td.trial_type })),
          //       'value',
          //     ), 'value');
          //     trialsTypeChoices.splice(0, 0, { label: "All", value: "" });
          //     this.trialsTypeRef = new Choices(trialsType, {
          //       choices: trialsTypeChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Types",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.trialFilters.selectedTrialType && this.trialsTypeRef) {
          //       this.trialsTypeRef.setChoiceByValue(this.trialFilters.selectedTrialType)
          //     }
          //     this.handleChoiseListener(trialsType, trialsTypeChoices, 'trialsTypeRef');
          //   }
          //   if (document.getElementById("trials_hearing_time")) {
          //     if (this.trialsHearingTimeRef) {
          //       this.trialsHearingTimeRef.destroy();
          //       this.trialsHearingTimeRef = null;
          //     }
          //     var trialsHearingTime = document.getElementById("trials_hearing_time");
          //     let trialsHearingTimeChoices = _.sortBy(_.uniqBy(
          //       this.getTrialsHearingTimeWise().filter(d => d['Jurisdiction(s)'] && d.Time)
          //         .map(td => ({ label: td.Time, value: td.Time })),
          //       'value',
          //     ), 'value');
          //     trialsHearingTimeChoices.splice(0, 0, { label: "All", value: "" });
          //     this.trialsHearingTimeRef = new Choices(trialsHearingTime, {
          //       choices: trialsHearingTimeChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Hearing Time",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.trialFilters.selectedTrialHearingTime && this.trialsHearingTimeRef) {
          //       this.trialsHearingTimeRef.setChoiceByValue(this.trialFilters.selectedTrialHearingTime)
          //     }
          //     this.handleChoiseListener(trialsHearingTime, trialsHearingTimeChoices, 'trialsHearingTimeRef');
          //   }
          //   if (document.getElementById("trials_venue")) {
          //     if (this.trialsVenueRef) {
          //       this.trialsVenueRef.destroy();
          //       this.trialsVenueRef = null;
          //     }
          //     var trialsVenue = document.getElementById("trials_venue");
          //     let trialsVenueChoices = _.sortBy(_.uniqBy(
          //       this.getTrialsVenueWise().filter(d => d.Venue).map(td => ({ label: td.Venue, value: td.Venue })),
          //       'value',
          //     ), 'value');
          //     trialsVenueChoices.splice(0, 0, { label: "All", value: "" });
          //     this.trialsVenueRef = new Choices(trialsVenue, {
          //       choices: trialsVenueChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Venue",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.trialFilters.selectedTrialVenue && this.trialsVenueRef) {
          //       this.trialsVenueRef.setChoiceByValue(this.trialFilters.selectedTrialVenue)
          //     }
          //     this.handleChoiseListener(trialsVenue, trialsVenueChoices, 'trialsVenueRef');
          //   }
          //   if (document.getElementById("trials_caseno")) {
          //     if (this.trialsCaseNoRef) {
          //       this.trialsCaseNoRef.destroy();
          //       this.trialsCaseNoRef = null;
          //     }
          //     var trialsCaseNo = document.getElementById("trials_caseno");
          //     let trialsCaseNoChoices = _.sortBy(_.uniqBy(
          //       this.getTrialsCaseNoWise().filter(d => d['Case No.']).map(td => ({ label: td['Case No.'], value: td['Case No.'] })),
          //       'value',
          //     ), 'value');
          //     trialsCaseNoChoices.splice(0, 0, { label: "All", value: "" });
          //     this.trialsCaseNoRef = new Choices(trialsCaseNo, {
          //       choices: trialsCaseNoChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Case No",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.trialFilters.selectedTrialCaseNo && this.trialsCaseNoRef) {
          //       this.trialsCaseNoRef.setChoiceByValue(this.trialFilters.selectedTrialCaseNo)
          //     }
          //     this.handleChoiseListener(trialsCaseNo, trialsCaseNoChoices, 'trialsCaseNoRef');
          //   }
          //   if (document.getElementById("trials_point_of_law")) {
          //     if (this.trialsPointOfLawRef) {
          //       this.trialsPointOfLawRef.destroy();
          //       this.trialsPointOfLawRef = null;
          //     }
          //     var trialsPointOfLaw = document.getElementById("trials_point_of_law");
          //     let trialsPointOfLawChoices = _.uniqBy(
          //       this.getTrialsPointOfLawWise().flatMap(trlDt => trlDt['Jurisdiction(s)'].split(','))
          //         .map(code => ({ label: code.trim(), value: code.trim() })),
          //       'value',
          //     );
          //     trialsPointOfLawChoices.splice(0, 0, { label: "All", value: "" });
          //     this.trialsPointOfLawRef = new Choices(trialsPointOfLaw, {
          //       choices: trialsPointOfLawChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Point of Law",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.trialFilters.selectedTrialPointOfLaw && this.trialsPointOfLawRef) {
          //       this.trialsPointOfLawRef.setChoiceByValue(this.trialFilters.selectedTrialPointOfLaw)
          //     }
          //     this.handleChoiseListener(trialsPointOfLaw, trialsPointOfLawChoices, 'trialsPointOfLawRef');
          //   }
          //   if (document.getElementById("trials_claimant")) {
          //     if (this.trialsClaimantRef) {
          //       this.trialsClaimantRef.destroy();
          //       this.trialsClaimantRef = null;
          //     }
          //     var trialsClaimant = document.getElementById("trials_claimant");
          //     let trialsClaimantChoices = _.sortBy(_.uniqBy(
          //       this.getTrialsClaimantWise().map(clmnt => ({
          //         label: (clmnt.Claimant || '').replace(/\n/g, ', '),
          //         value: (clmnt.Claimant || '').split("\n")[0]
          //       })),
          //       'value',
          //     ), 'value');
          //     trialsClaimantChoices.splice(0, 0, { label: "All", value: "" });
          //     this.trialsClaimantRef = new Choices(trialsClaimant, {
          //       choices: trialsClaimantChoices,
          //       allowHTML: true,
          //       shouldSort: false,
          //       itemSelectText: "",
          //       searchEnabled: true,
          //       searchPlaceholderValue: "Search Claimant",
          //       classNames: {
          //         containerOuter: "choices custom_menu_list"
          //       }
          //     });
          //     if (this.trialFilters.selectedTrialClaimant && this.trialsClaimantRef) {
          //       this.trialsClaimantRef.setChoiceByValue(this.trialFilters.selectedTrialClaimant)
          //     }
          //     this.handleChoiseListener(trialsClaimant, trialsClaimantChoices, 'trialsClaimantRef');
          //   }
          // }
        } catch (error) {
          console.log("error", error);
        }
      }, 100);
    },
    handleCasesDataChange() {
      const caseId = this.cases[0].id || '';
      this.updateCaseDetailFilters({ selectedCase: caseId });

      if (this.case_details_caseRef) {
        const casesChoices = this.cases.filter(c => c.id).map(c => ({ label: c.caseName, value: c.id }));
        this.case_details_caseRef.setChoices(casesChoices, "value", "label", true);
        this.case_details_caseRef.setChoiceByValue(caseId);
        const caseElement = document.getElementById("case_details_case");
        this.handleChoiseListener(caseElement, casesChoices, 'case_details_caseRef');
      }
    },
    handleCompanyDataUpdate() {
      const firstCompany = this.companies.find(c => c.id) || {};
      this.updateCompanyFilters({ selectedCompany: String(firstCompany.id || "") });

      if (this.company_companyRef) {
        const companyChoices = this.companies.map(c => ({ label: c.name, value: String(c.id || '') }));
        this.company_companyRef.setChoices(companyChoices, "value", "label", true);
        this.company_companyRef.setChoiceByValue(firstCompany.id || '');
        const companyCompanyElement = document.getElementById("company_company");
        this.handleChoiseListener(companyCompanyElement, companyChoices, 'company_companyRef');
      }
    },
    handleMainGPGCompanyDataUpdate() {
      const firstCompany = this.gpgCompanies.find(c => c?.CompanyNumber) || {};
      this.updateGPGFilters({ ...this.gpgFilters, selectedCompany: firstCompany?.CompanyNumber || "" });

      if (this.gpg_companyRef) {
        const gpgCompanyChoices = _.uniqBy(this.gpgCompanies.map(c => ({
          label: c?.CurrentName || c?.EmployerName || '',
          value: c?.CompanyNumber || '',
        })), 'value');
        this.gpg_companyRef.setChoices(gpgCompanyChoices, "value", "label", true);
        this.gpg_companyRef.setChoiceByValue(firstCompany?.CompanyNumber || "");
        const gpgCompanyElement = document.getElementById("gpg_company");
        this.handleChoiseListener(gpgCompanyElement, gpgCompanyChoices, 'gpg_companyRef');
      }
    },
    handleGPGCompanyDataUpdate(setEmptyCompetitors = false) {
      // const indWiseCompanyData = {};
      // uniqBy(this.industries, 'GroupDesc').forEach(ind => {
      //   indWiseCompanyData[ind.GroupDesc] = [...(indWiseCompanyData[ind.GroupDesc] || []), ...this.gpgCompanies.filter(c => c.industries.some(ci => ci?.GroupDesc === ind.GroupDesc))];
      // });
      // const indWiseCompany = Object.entries(indWiseCompanyData).map(([key, value]) => ({
      //   industry: key,
      //   companies: value,
      // }));

      // const selectedGPGCompanyData = this.gpgCompanies.find(c => c?.CompanyNumber == this.gpgFilters.selectedCompany) || {};
      // const sameIndCompaniesData = indWiseCompany.find(c => (selectedGPGCompanyData?.industries || []).some(cmpInd => (cmpInd?.GroupDesc || '') == c.industry)) || {};
      // const otherGPGCompaniesFromSameIndustry = (sameIndCompaniesData?.companies || []).filter(c => String(c.id || '') != selectedGPGCompanyData.id);

      // const gpgCompetitirOptions = otherGPGCompaniesFromSameIndustry.map(c => ({ label: c.name, value: String(c.id || '') }));
      const gpgCompetitirOptions = _.sortBy(_.uniqBy(
        this.gpgCompanies.filter(c => c?.CompanyNumber != this.gpgFilters.selectedCompany)
          .map(c => ({
            label: c?.CurrentName || c?.EmployerName || '',
            value: c?.CompanyNumber || '',
          }))
        , 'value'), 'label');
      // if (gpgCompetitirOptions.length <= 0) {
      //   return null;
      // }
      if (setEmptyCompetitors) {
        this.updateGPGFilters({ ...this.gpgFilters, selectedGPGCompetitors: [] });
      }
      // if (gpgCompetitirOptions[0].value && this.gpgFilters.selectedGPGCompetitors.length < 1) {
      //   this.updateGPGFilters({ ...this.gpgFilters, selectedGPGCompetitors: [gpgCompetitirOptions[0].value] });
      // }
      if (this.gpg_competitorRef) {
        this.gpg_competitorRef.clearStore();
        this.gpg_competitorRef.setChoices(gpgCompetitirOptions, "value", "label", true);
        if (this.gpgFilters.selectedGPGCompetitors.length > 0) {
          this.gpgFilters.selectedGPGCompetitors.forEach(val => {
            this.gpg_competitorRef.setChoiceByValue(val);
          });
        } else {
          this.gpg_competitorRef.setChoiceByValue('');
          // if (gpgCompetitirOptions[0].value) {
          //   this.gpg_competitorRef.setChoiceByValue(gpgCompetitirOptions[0].value);
          // }
        }
        var gpgCompetitorElement = document.getElementById("gpg_competitor");
        this.handleChoiseListener(gpgCompetitorElement, gpgCompetitirOptions, 'gpg_competitorRef');
      }
    },
    getCasesIndustryWise() {
      return this.cases.filter(
        cs =>
        (
          (!this.searchQuery || ((cs?.caseName || '').toLowerCase().includes(this.searchQuery.toLowerCase())))
          && (
            this.casesFilters.selectedCasesDate.length == 0
            || (
              (cs.industryData.length > 0)
              && (this.casesFilters.selectedCasesDate.length > 0 && cs.caseDate && isDateBetween(this.casesFilters.selectedCasesDate, cs.caseDate))
            )
          )
        )
      );
    },
    getCasesSubIndustryWise() {
      return this.getCasesIndustryWise().filter(
        cs =>
        (
          !this.casesFilters.selectedIndustry
          || (
            (cs.industryData.length > 0)
            && cs.industryData.map(i => i?.GroupDesc).includes(this.casesFilters.selectedIndustry)
          )
        )
      );
    },
    getCasesCompanyWise() {
      return this.getCasesSubIndustryWise().filter(
        cs => (
          !this.casesFilters.selectedCompany
          || (cs.companyData || []).map(c => c.name).includes(this.casesFilters.selectedCompany)
        )
      );
    },
    getCasesCityWise() {
      return this.getCasesCompanyWise().filter(
        cs => (
          !this.casesFilters.selectedCity
          || (cs?.city_aggregated || []).includes(this.casesFilters.selectedCity)
        )
      );
    },
    getCasesResultWise() {
      return this.getCasesCityWise().filter(
        cs => (
          !this.casesFilters.selectedResult
          || (cs?.result_aggregated || []).includes(this.casesFilters.selectedResult)
        )
      );
    },
    getCasesPointOfLawWise() {
      return this.getCasesResultWise().filter(
        cs => (
          !this.casesFilters.selectedPointOfLaw
          || (cs.category || []).includes(this.casesFilters.selectedPointOfLaw)
        )
      );
    },
    getCasesJudgeWise() {
      return this.getCasesPointOfLawWise().filter(
        cs => (
          !this.casesFilters.selectedJudge
          || (cs.Judge || '') === this.casesFilters.selectedJudge
        )
      );
    },
    getCasesCaseTypeWise() {
      return this.getCasesJudgeWise().filter(
        cs => (
          !this.casesFilters.selectedCaseType
          || (cs.caseType || '') === this.casesFilters.selectedCaseType
        )
      );
    },
    getIndustry_Industries() {
      return this.cases.filter(
        cs => (
          !this.industryFilters.selectedYear
          || (cs.caseDate && moment(cs.caseDate, 'YYYY-MM-DD HH:mm').isSame(this.industryFilters.selectedYear, 'year'))
        )
      );
    },
    getIndustry_SubIndustries() {
      return this.getIndustry_Industries().filter(
        cs => (
          !this.industryFilters.selectedIndustry
          || (cs.industryData || []).some(ind => ind?.GroupDesc == this.industryFilters.selectedIndustry)
        )
      );
    },
    getIndustry_CaseTypes() {
      return this.getIndustry_SubIndustries().filter(
        cs => (
          !this.industryFilters.selectedSubIndustry
          || (cs.industryData || []).some(ind => ind?.CodeDesc == this.industryFilters.selectedSubIndustry)
        )
      );
    },
    handleChoiseListener(element, choices, elementRef) {
      if (element && choices.length && this[elementRef]) {
        element.addEventListener('search', (e) => {
          const query = String(e?.detail?.value || '').toLowerCase();
          const newCompanyChoices = choices.filter(c => (c.label || '').toLowerCase().includes(query));
          this[elementRef]?.setChoices(newCompanyChoices, 'value', 'label', true);
        });
        element.addEventListener('hideDropdown', () => {
          this[elementRef]?.setChoices(choices, 'value', 'label', true);
        });
      }
    },
    changeCasesOptions(type) {
      if (type === 'date' && this.industriesRef) {
        this.industriesRef.clearChoices();
        this.industriesRef.clearStore();
        const indData = this.getCasesIndustryWise().flatMap(c => c.industryData).filter(ind => !!ind?.GroupDesc);
        let industryChoices = _.sortBy(_.uniqBy(indData.map(i => ({
          label: i.GroupDesc,
          value: i.GroupDesc,
        })), 'value'), 'value');
        industryChoices.splice(0, 0, { label: "All", value: "" });
        this.industriesRef.setChoices(industryChoices, "value", "label", true);
        this.industriesRef.setChoiceByValue('');
        var industries = document.getElementById("industries");
        this.handleChoiseListener(industries, industryChoices, 'industriesRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
        )
        && this.subIndustryRef) {
        this.subIndustryRef.clearChoices();
        this.subIndustryRef.clearStore();
        const subInd = this.getCasesSubIndustryWise().flatMap(c => c.industryData).filter(ind => !!ind?.CodeDesc);
        let subIndustriesChoices = _.sortBy(_.uniqBy(subInd.map(i => ({
          label: i.CodeDesc,
          value: i.CodeDesc,
        })), 'value'), 'value');
        subIndustriesChoices.splice(0, 0, { label: "All", value: "" });
        this.subIndustryRef.setChoices(subIndustriesChoices, "value", "label", true);
        this.subIndustryRef.setChoiceByValue('');
        const subIndustriesElement = document.getElementById("sub-industries");
        this.handleChoiseListener(subIndustriesElement, subIndustriesChoices, 'subIndustryRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
          || type === 'subIndustries'
        ) && this.companyRef) {
        this.companyRef.clearChoices();
        this.companyRef.clearStore();
        const companies = this.getCasesCompanyWise().filter(c => (c?.companyData || []).length > 0);
        let companyChoices = _.sortBy(_.uniqBy(companies.flatMap(c => (c?.companyData || [])).map(i => ({
          label: (i?.name || ''),
          value: (i?.name || ''),
        })), 'value'), 'value');
        companyChoices.splice(0, 0, { label: "All", value: "" });
        this.companyRef.setChoices(companyChoices, "value", "label", true);
        this.companyRef.setChoiceByValue('');
        const companyElement = document.getElementById("company");
        this.handleChoiseListener(companyElement, companyChoices, 'companyRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
          || type === 'subIndustries'
          || type === 'company'
        ) && this.citiesRef) {
        this.citiesRef.clearChoices();
        this.citiesRef.clearStore();
        const cCities = this.getCasesCityWise().filter(c => (c?.city_aggregated || []).length > 0);
        let cityChoices = _.sortBy(_.uniqBy(cCities.flatMap(c => (c?.city_aggregated || [])).map(i => ({
          label: i,
          value: i,
        })), 'value'), 'value');
        cityChoices.splice(0, 0, { label: "All", value: "" });
        this.citiesRef.setChoices(cityChoices, "value", "label", true);
        this.citiesRef.setChoiceByValue('');
        const citiesElement = document.getElementById("cities");
        this.handleChoiseListener(citiesElement, cityChoices, 'citiesRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
          || type === 'subIndustries'
          || type === 'company'
          || type === 'cities'
        ) && this.resultRef) {
        this.resultRef.clearChoices();
        this.resultRef.clearStore();
        const results = this.getCasesResultWise().filter(c => (c?.result_aggregated || [])).flatMap(c => (c?.result_aggregated || []));
        let resultChoices = _.sortBy(_.uniqBy(results.map(i => ({
          label: i,
          value: i,
        })), 'value'), 'value');
        resultChoices.splice(0, 0, { label: "All", value: "" });

        this.resultRef.setChoices(resultChoices, "value", "label", true);
        this.resultRef.setChoiceByValue('');
        const resultElement = document.getElementById("result");
        this.handleChoiseListener(resultElement, resultChoices, 'resultRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
          || type === 'subIndustries'
          || type === 'company'
          || type === 'cities'
          || type === 'result'
        ) && this.pointOfLawRef) {
        this.pointOfLawRef.clearChoices();
        this.pointOfLawRef.clearStore();
        const jurCodes = _.uniq(this.getCasesPointOfLawWise().flatMap(c => c.category || []));
        let pointOfLawChoices = _.sortBy(jurCodes.map(i => ({
          label: i,
          value: i,
        })), 'value');
        pointOfLawChoices.splice(0, 0, { label: "All", value: "" });

        this.pointOfLawRef.setChoices(pointOfLawChoices, "value", "label", true);
        this.pointOfLawRef.setChoiceByValue('');
        const pointOfLawCodeElement = document.getElementById("point_of_law");
        this.handleChoiseListener(pointOfLawCodeElement, pointOfLawChoices, 'pointOfLawRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
          || type === 'subIndustries'
          || type === 'company'
          || type === 'cities'
          || type === 'result'
          || type === 'point_of_law'
        ) && this.judgeRef) {
        this.judgeRef.clearChoices();
        this.judgeRef.clearStore();
        const judges = this.getCasesJudgeWise().flatMap(c => (c.heardBefore_aggregated || []));
        let judgeChoices = _.sortBy(_.uniqBy(judges.map(j => ({
          label: j,
          value: j,
        })), 'value'), 'value');
        judgeChoices.splice(0, 0, { label: "All", value: "" });
        this.judgeRef.setChoices(judgeChoices, "value", "label", true);
        this.judgeRef.setChoiceByValue('');
        const judgeElement = document.getElementById("judge");
        this.handleChoiseListener(judgeElement, judgeChoices, 'judgeRef');
      }
      if (
        (
          type === 'date'
          || type === 'industries'
          || type === 'subIndustries'
          || type === 'company'
          || type === 'cities'
          || type === 'result'
          || type === 'point_of_law'
          || type === 'judge'
        ) && this.caseTypeRef) {
        this.caseTypeRef.clearChoices();
        this.caseTypeRef.clearStore();
        let caseTypeChoices = _.sortBy(_.uniqBy(this.getCasesCaseTypeWise().map(i => ({
          label: i.caseType,
          value: i.caseType,
        })), 'value'), 'value');
        caseTypeChoices.splice(0, 0, { label: "All", value: "" });
        this.caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
        this.caseTypeRef.setChoiceByValue('');
        const caseTypeElement = document.getElementById("case_type");
        this.handleChoiseListener(caseTypeElement, caseTypeChoices, 'caseTypeRef');
      }
    },
    changeIndustryOptions(type) {
      if (type == 'year' && this.industry_industriesRef) {
        this.industry_industriesRef.clearChoices();
        this.industry_industriesRef.clearStore();
        const industriesData = this.getIndustry_Industries().flatMap(ind => ind.industryData).filter(f => !!f?.GroupDesc);
        let indChoices = _.sortBy(_.uniqBy(industriesData.map(cs => ({
          label: cs.GroupDesc,
          value: cs.GroupDesc,
        })), 'value'), 'value');
        indChoices.splice(0, 0, { label: "Select Industries", value: "" })
        var industryIndustriesElement = document.getElementById("industry_industries");
        this.industry_industriesRef.setChoices(indChoices, "value", "label", true);
        this.industry_industriesRef.setChoiceByValue('');
        this.handleChoiseListener(industryIndustriesElement, indChoices, 'industry_industriesRef');
      }
      if (
        (type == 'year' || type == 'industry')
        && this.industry_sub_industriesRef
      ) {
        this.industry_sub_industriesRef.clearChoices();
        this.industry_sub_industriesRef.clearStore();
        const subIndustriesData = this.getIndustry_SubIndustries().flatMap(ind => ind.industryData).filter(f => !!f?.CodeDesc);
        let subIndChoices = _.sortBy(_.uniqBy(subIndustriesData.map(cs => ({
          label: cs.CodeDesc,
          value: cs.CodeDesc,
        })), 'value'), 'value');
        subIndChoices.splice(0, 0, { label: "Select Sub-Industries", value: "" })
        var industrySubIndustriesElement = document.getElementById("industry_sub-industries");
        this.industry_sub_industriesRef.setChoices(subIndChoices, "value", "label", true);
        this.industry_sub_industriesRef.setChoiceByValue('');
        this.handleChoiseListener(industrySubIndustriesElement, subIndChoices, 'industry_sub_industriesRef');
      }
      if (
        (type == 'year' || type == 'industry' || type == 'sub-industry')
        && this.industry_caseTypeRef
      ) {
        this.industry_caseTypeRef.clearChoices();
        this.industry_caseTypeRef.clearStore();

        let caseTypeChoices = _.sortBy(_.uniqBy(this.getIndustry_CaseTypes().map(cs => ({
          label: cs.caseType,
          value: cs.caseType,
        })), 'value'), 'value');
        caseTypeChoices.splice(0, 0, { label: "Select Case Type", value: "" });
        this.industry_caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
        this.industry_caseTypeRef.setChoiceByValue("");
        const industryCaseTypeElement = document.getElementById("industry_case_type");
        this.handleChoiseListener(industryCaseTypeElement, caseTypeChoices, 'industry_caseTypeRef');
      }
    },
    onCasesDateChange(date) {
      const dates = (date || []).filter(d => d);
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedCasesDate: dates,
        selectedIndustry: "",
        selectedSubIndustry: "",
        selectedCompany: "",
        selectedCity: "",
        selectedResult: "",
        selectedPointOfLaw: "",
        selectedJudge: "",
        selectedCaseType: "",
      });
      setTimeout(() => {
        this.changeCasesOptions('date');
      }, 1000);
    },
    onIndustryChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedIndustry: value,
        selectedSubIndustry: "",
        selectedCompany: "",
        selectedCity: "",
        selectedResult: "",
        selectedPointOfLaw: "",
        selectedJudge: "",
        selectedCaseType: "",
      });
      setTimeout(() => {
        this.changeCasesOptions('industries');
      }, 1000);
    },
    onSubIndustryChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedSubIndustry: value,
        selectedCompany: "",
        selectedCity: "",
        selectedResult: "",
        selectedPointOfLaw: "",
        selectedJudge: "",
        selectedCaseType: "",
      });
      setTimeout(() => {
        this.changeCasesOptions('subIndustries');
      }, 1000);
    },
    onCompanyChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedCompany: value,
        selectedCity: "",
        selectedResult: "",
        selectedPointOfLaw: "",
        selectedJudge: "",
        selectedCaseType: "",
      });
      setTimeout(() => {
        this.changeCasesOptions('company');
      }, 1000);
    },
    onCityChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedCity: value,
        selectedResult: "",
        selectedPointOfLaw: "",
        selectedJudge: "",
        selectedCaseType: "",
      });
      setTimeout(() => {
        this.changeCasesOptions('cities');
      }, 1000);
    },
    onResultChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedPointOfLaw: "",
        selectedJudge: "",
        selectedCaseType: "",
        selectedResult: value,
      });
      setTimeout(() => {
        this.changeCasesOptions('result');
      }, 1000);
    },
    onPointOfLawChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedJudge: "",
        selectedCaseType: "",
        selectedPointOfLaw: value,
      });
      setTimeout(() => {
        this.changeCasesOptions('point_of_law');
      }, 1000);
    },
    onJudgeChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedCaseType: "",
        selectedJudge: value,
      });
      setTimeout(() => {
        this.changeCasesOptions('judge');
      }, 1000);
    },
    onCaseTypeChange(value) {
      this.updateCasesFilters({
        ...this.casesFilters,
        selectedCaseType: value,
      });
    },
    casesDatePickerformat(dates) {
      return (dates || []).filter(d => d).map(date => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
      }).join(" to ");
    },
    onCaseDetailsCaseChange(value) {
      this.updateCaseDetailFilters(({ selectedCase: value }));
    },
    onCompanyCompanyChange(value) {
      this.updateCompanyFilters(({ selectedCompany: value }));
    },
    onGPGCompanyChange(value) {
      if (this.gpg_competitorRef) {
        this.gpg_competitorRef.clearStore();
      }
      this.updateGPGFilters({
        selectedCompany: value,
        selectedGPGCompetitors: [],
      });
    },
    onGPGCompetitorChange(changedValue) {
      const selectedGPGCompetitors = Object.entries(changedValue?.options).map(([, option]) => option.value);
      this.updateGPGFilters({ ...this.gpgFilters, selectedGPGCompetitors });
      // if (this.gpgFilters.selectedGPGCompetitors.includes(changedValue)) {
      //   this.updateGPGFilters({
      //     ...this.gpgFilters,
      //     selectedGPGCompetitors: this.gpgFilters.selectedGPGCompetitors.filter(op => op != changedValue),
      //   });
      // } else {
      //   this.updateGPGFilters({
      //     ...this.gpgFilters,
      //     selectedGPGCompetitors: [...this.gpgFilters.selectedGPGCompetitors, changedValue],
      //   });
      // }
    },
    onIndustryYearChange(value) {
      this.updateIndustryFilters({
        ...this.industryFilters,
        selectedYear: value,
        selectedIndustry: '',
        selectedSubIndustry: '',
        selectedCaseType: '',
      });
      this.changeIndustryOptions('year');
    },
    onIndustryIndustriesChange(value) {
      this.updateIndustryFilters({
        ...this.industryFilters,
        selectedIndustry: value,
        selectedSubIndustry: '',
        selectedCaseType: '',
      });
      this.changeIndustryOptions('industry');
    },
    onIndustrySubIndustryChange(value) {
      this.updateIndustryFilters({
        ...this.industryFilters,
        selectedSubIndustry: value,
        selectedCaseType: '',
      });
      this.changeIndustryOptions('sub-industry');
    },
    onIndustryCaseTypeChange(value) {
      this.updateIndustryFilters({ ...this.industryFilters, selectedCaseType: value });
    },
    onLNRTribunalTypeChange(value) {
      this.updateLNRFilters({ ...this.lnrFilters, selectedTribunalType: value });
    },
    onLNRLawTypeChange(value) {
      this.updateLNRFilters({ ...this.lnrFilters, selectedLawType: value });
    },
    // changeTrialsOptions(type) {
    //   if (type === 'trials_date' && this.trialsTypeRef) {
    //     this.trialsTypeRef.clearChoices();
    //     this.trialsTypeRef.clearStore();
    //     let trialsTypeChoices = _.sortBy(_.uniqBy(
    //       this.getTrialsTypeWise().filter(d => d.trial_type).map(td => ({ label: td.trial_type, value: td.trial_type })),
    //       'value',
    //     ), 'value');
    //     trialsTypeChoices.splice(0, 0, { label: "All", value: "" });
    //     this.trialsTypeRef.setChoices(trialsTypeChoices, "value", "label", true);
    //     this.trialsTypeRef.setChoiceByValue('');
    //     const trialsTypeElement = document.getElementById("trials_type");
    //     this.handleChoiseListener(trialsTypeElement, trialsTypeChoices, 'trialsTypeRef');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type')
    //     && this.trialsHearingTimeRef
    //   ) {
    //     this.trialsHearingTimeRef.clearChoices();
    //     this.trialsHearingTimeRef.clearStore();
    //     let trialsHearingTimeChoices = _.sortBy(_.uniqBy(
    //       this.getTrialsHearingTimeWise().filter(d => d['Jurisdiction(s)'] && d.Time)
    //         .map(td => ({ label: td.Time, value: td.Time })),
    //       'value',
    //     ), 'value');
    //     trialsHearingTimeChoices.splice(0, 0, { label: "All", value: "" });
    //     this.trialsHearingTimeRef.setChoices(trialsHearingTimeChoices, "value", "label", true);
    //     this.trialsHearingTimeRef.setChoiceByValue('');
    //     const trialsHearingTimeElement = document.getElementById("trials_hearing_time");
    //     this.handleChoiseListener(trialsHearingTimeElement, trialsHearingTimeChoices, 'trialsHearingTimeRef');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time')
    //     && this.trialsVenueRef
    //   ) {
    //     this.trialsVenueRef.clearChoices();
    //     this.trialsVenueRef.clearStore();
    //     let trialsVenueChoices = _.sortBy(_.uniqBy(
    //       this.getTrialsVenueWise().filter(d => d.Venue).map(td => ({ label: td.Venue, value: td.Venue })),
    //       'value',
    //     ), 'value');
    //     trialsVenueChoices.splice(0, 0, { label: "All", value: "" });
    //     this.trialsVenueRef.setChoices(trialsVenueChoices, "value", "label", true);
    //     this.trialsVenueRef.setChoiceByValue('');
    //     const trialsVenueElement = document.getElementById("trials_venue");
    //     this.handleChoiseListener(trialsVenueElement, trialsVenueChoices, 'trialsVenueRef');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time' || type === 'trials_venue')
    //     && this.trialsCaseNoRef
    //   ) {
    //     this.trialsCaseNoRef.clearChoices();
    //     this.trialsCaseNoRef.clearStore();
    //     let trialsCaseNoChoices = _.sortBy(_.uniqBy(
    //       this.getTrialsCaseNoWise().filter(d => d['Case No.']).map(td => ({ label: td['Case No.'], value: td['Case No.'] })),
    //       'value',
    //     ), 'value');
    //     trialsCaseNoChoices.splice(0, 0, { label: "All", value: "" });
    //     this.trialsCaseNoRef.setChoices(trialsCaseNoChoices, "value", "label", true);
    //     this.trialsCaseNoRef.setChoiceByValue('');
    //     const trialsCaseNoElement = document.getElementById("trials_caseno");
    //     this.handleChoiseListener(trialsCaseNoElement, trialsCaseNoChoices, 'trialsCaseNoRef');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time' || type === 'trials_venue') || type === 'trials_caseno'
    //     && this.trialsPointOfLawRef
    //   ) {
    //     this.trialsPointOfLawRef.clearChoices();
    //     this.trialsPointOfLawRef.clearStore();
    //     let trialsPointOfLawChoices = _.uniqBy(
    //       this.getTrialsPointOfLawWise().flatMap(trlDt => trlDt['Jurisdiction(s)'].split(','))
    //         .map(code => ({ label: code.trim(), value: code.trim() })),
    //       'value',
    //     );
    //     trialsPointOfLawChoices.splice(0, 0, { label: "All", value: "" });
    //     this.trialsPointOfLawRef.setChoices(trialsPointOfLawChoices, "value", "label", true);
    //     this.trialsPointOfLawRef.setChoiceByValue('');
    //     const trialsPointOfLawElement = document.getElementById("trials_point_of_law");
    //     this.handleChoiseListener(trialsPointOfLawElement, trialsPointOfLawChoices, 'trialsPointOfLawRef');
    //   }
    //   if (
    //     (type === 'trials_date' || type == 'trials_type' || type == 'trials_hearing_time' || type === 'trials_venue') || type === 'trials_caseno' || type === 'trials_point_of_law'
    //     && this.trialsClaimantRef
    //   ) {
    //     this.trialsClaimantRef.clearChoices();
    //     this.trialsClaimantRef.clearStore();
    //     let trialsClaimantChoices = _.sortBy(_.uniqBy(
    //       this.getTrialsClaimantWise().map(clmnt => ({
    //         label: (clmnt.Claimant || '').replace(/\n/g, ', '),
    //         value: (clmnt.Claimant || '').split("\n")[0]
    //       })),
    //       'value',
    //     ), 'value');
    //     trialsClaimantChoices.splice(0, 0, { label: "All", value: "" });
    //     this.trialsClaimantRef.setChoices(trialsClaimantChoices, "value", "label", true);
    //     this.trialsClaimantRef.setChoiceByValue('');
    //     const trialsClaimantElement = document.getElementById("trials_claimant");
    //     this.handleChoiseListener(trialsClaimantElement, trialsClaimantChoices, 'trialsClaimantRef');
    //   }
    // },
    // getTrialsTypeWise() {
    //   const allTrials = [...this.trialsData].map(d => ({
    //     ...d,
    //     'Hearing Date': d['Hearing Date'] && d['Hearing Date'] != 'NaT' ? d['Hearing Date'] : '',
    //     'Time': d['Time'] && d['Time'] != 'NaT' ? moment(d['Time'].split('.')[0], 'HH:mm:ss').format("HH:mm") : '',
    //   })).filter(
    //     trl => (
    //       this.trialFilters.selectedTrialDate.length == 0
    //       || (this.trialFilters.selectedTrialDate.length > 0 && (trl['Hearing Date'] || '') && isDateBetween(this.trialFilters.selectedTrialDate, trl['Hearing Date']))
    //     )
    //   );
    //   return allTrials;
    // },
    // getTrialsHearingTimeWise() {
    //   return this.getTrialsTypeWise().filter(trl =>
    //     (trl.Time || '')
    //     && (
    //       this.trialFilters.selectedTrialHearingTime === ''
    //       || this.trialFilters.selectedTrialHearingTime === trl.Time
    //     )
    //   );
    // },
    // getTrialsVenueWise() {
    //   return this.getTrialsHearingTimeWise().filter(trl =>
    //     (trl.Venue || '')
    //     && (
    //       this.trialFilters.selectedTrialVenue === ''
    //       || this.trialFilters.selectedTrialVenue === trl.Venue
    //     )
    //   );
    // },
    // getTrialsCaseNoWise() {
    //   return this.getTrialsVenueWise().filter(trl =>
    //     (trl['Case No.'] || '')
    //     && (
    //       this.trialFilters.selectedTrialCaseNo === ''
    //       || this.trialFilters.selectedTrialCaseNo === trl['Case No.']
    //     )
    //   );
    // },
    // getTrialsPointOfLawWise() {
    //   return this.getTrialsCaseNoWise().filter(trl =>
    //     (trl['Jurisdiction(s)'] || '')
    //     && (
    //       this.trialFilters.selectedTrialPointOfLaw === ''
    //       || trl['Jurisdiction(s)'].includes(this.trialFilters.selectedTrialPointOfLaw)
    //     )
    //   );
    // },
    // getTrialsClaimantWise() {
    //   return this.getTrialsPointOfLawWise().filter(trl =>
    //     (trl.Claimant || '')
    //     && (
    //       this.trialFilters.selectedTrialClaimant === ''
    //       || (trl.Claimant || '').toLowerCase().includes(this.trialFilters.selectedTrialClaimant.toLowerCase())
    //     )
    //   );
    // },
    // onTrialsDateChange(date) {
    //   const dates = (date || []).filter(d => d);
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialType: '',
    //     selectedTrialVenue: '',
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialHearingTime: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialDate: dates,
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_date');
    //   }, 1000);
    // },
    // onTrialsTypeChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialVenue: '',
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialHearingTime: '',
    //     selectedTrialType: e.target.value,
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_type');
    //   }, 1000);
    // },
    // onTrialsHearingTimeChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialVenue: '',
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialHearingTime: e.target.value,
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_hearing_time');
    //   }, 1000);
    // },
    // onTrialsVenueChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialCaseNo: '',
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialVenue: e.target.value
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_venue');
    //   }, 1000);
    // },
    // onTrialsCaseNoChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: '',
    //     selectedTrialCaseNo: e.target.value
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_caseno');
    //   }, 1000);
    // },
    // onTrialsPointOfLawChange(e) {
    //   this.updateTrialFilter({
    //     ...this.trialFilters,
    //     selectedTrialClaimant: '',
    //     selectedTrialPointOfLaw: e.target.value
    //   });
    //   setTimeout(() => {
    //     this.changeTrialsOptions('trials_point_of_law');
    //   }, 1000);
    // },
    // onTrialsClaimantChange(e) {
    //   this.updateTrialFilter({ ...this.trialFilters, selectedTrialClaimant: e.target.value });
    // },
    // trialDatePickerformat(dates) {
    //   return (dates || []).filter(d => d).map(date => {
    //     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    //     const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    //     const month = months[date.getMonth()];
    //     const year = date.getFullYear();
    //     return `${day} ${month} ${year}`;
    //   }).join(" to ");
    // },
    resetTabFilters() {
      eventBus.emit('on-reset-filter');
      this.isResetting = true;
      if (this.activeTab === 'cases') {
        this.searchQuery = '';
        this.updateCasesFilters({
          selectedCity: "",
          selectedJudge: "",
          selectedCasesDate: [],
          selectedCompany: "",
          selectedIndustry: "",
          selectedResult: "",
          selectedPointOfLaw: "",
          selectedSubIndustry: "",
          selectedCaseType: "",
        });
        setTimeout(() => {
          if (this.industriesRef) {
            const indData = this.getCasesIndustryWise().flatMap(c => c.industryData).filter(ind => !!ind?.GroupDesc);
            let industryChoices = _.sortBy(_.uniqBy(indData.map(i => ({
              label: i.GroupDesc,
              value: i.GroupDesc,
            })), 'value'), 'value');
            industryChoices.splice(0, 0, { label: "All", value: "" });
            this.industriesRef.setChoices(industryChoices, "value", "label", true);
            this.industriesRef.setChoiceByValue("");
            const industryElement = document.getElementById('industries');
            this.handleChoiseListener(industryElement, industryChoices, 'industriesRef');
          }
          if (this.subIndustryRef) {
            const subInd = this.getCasesSubIndustryWise().flatMap(c => c.industryData).filter(ind => !!ind?.CodeDesc);
            let subIndustriesChoices = _.sortBy(_.uniqBy(subInd.map(i => ({
              label: i.CodeDesc,
              value: i.CodeDesc,
            })), 'value'), 'value');
            subIndustriesChoices.splice(0, 0, { label: "All", value: "" });
            this.subIndustryRef.setChoices(subIndustriesChoices, "value", "label", true);
            this.subIndustryRef.setChoiceByValue('');
            const subIndustriesElement = document.getElementById("sub-industries");
            this.handleChoiseListener(subIndustriesElement, subIndustriesChoices, 'subIndustryRef');
          }
          if (this.companyRef) {
            const companies = this.getCasesCompanyWise().filter(c => (c?.companyData || []).length > 0);
            let companyChoices = _.sortBy(_.uniqBy(companies.flatMap(c => (c?.companyData || [])).map(i => ({
              label: (i?.name || ''),
              value: (i?.name || ''),
            })), 'value'), 'value');
            companyChoices.splice(0, 0, { label: "All", value: "" });
            this.companyRef.setChoices(companyChoices, "value", "label", true);
            this.companyRef.setChoiceByValue('');
            const companyElement = document.getElementById("company");
            this.handleChoiseListener(companyElement, companyChoices, 'companyRef');
          }
          if (this.citiesRef) {
            const cCities = this.getCasesCityWise().filter(c => (c?.city_aggregated || []).length > 0);
            let cityChoices = _.sortBy(_.uniqBy(cCities.flatMap(c => (c?.city_aggregated || [])).map(i => ({
              label: i,
              value: i,
            })), 'value'), 'value');
            cityChoices.splice(0, 0, { label: "All", value: "" });
            this.citiesRef.setChoices(cityChoices, "value", "label", true);
            this.citiesRef.setChoiceByValue("");
            const cityElement = document.getElementById("cities");
            this.handleChoiseListener(cityElement, cityChoices, 'citiesRef');
          }
          if (this.resultRef) {
            const results = this.getCasesResultWise().filter(c => (c?.result_aggregated || [])).flatMap(c => (c?.result_aggregated || []));
            let resultChoices = _.sortBy(_.uniqBy(results.map(i => ({
              label: i,
              value: i,
            })), 'value'), 'value');
            resultChoices.splice(0, 0, { label: "All", value: "" });
            this.resultRef.setChoices(resultChoices, "value", "label", true);
            this.resultRef.setChoiceByValue("");
            const resultElement = document.getElementById("result");
            this.handleChoiseListener(resultElement, resultChoices, 'resultRef');
          }
          if (this.pointOfLawRef) {
            const jurCodes = _.uniq(this.getCasesPointOfLawWise().flatMap(c => c.category || []));
            let pointOfLawChoices = _.sortBy(jurCodes.map(i => ({
              label: i,
              value: i,
            })), 'value');
            pointOfLawChoices.splice(0, 0, { label: "All", value: "" });
            this.pointOfLawRef.setChoices(pointOfLawChoices, "value", "label", true);
            this.pointOfLawRef.setChoiceByValue("");
            const pointOfLawElement = document.getElementById("point_of_law");
            this.handleChoiseListener(pointOfLawElement, pointOfLawChoices, 'pointOfLawRef');
          }
          if (this.judgeRef) {
            const judges = this.getCasesJudgeWise().flatMap(c => (c.heardBefore_aggregated || []));
            let judgeChoices = _.sortBy(_.uniqBy(judges.map(j => ({
              label: j,
              value: j,
            })), 'value'), 'value');
            judgeChoices.splice(0, 0, { label: "All", value: "" });
            this.judgeRef.setChoices(judgeChoices, "value", "label", true);
            this.judgeRef.setChoiceByValue("");
            const judgeElement = document.getElementById("judge");
            this.handleChoiseListener(judgeElement, judgeChoices, 'judgeRef');
          }
          if (this.caseTypeRef) {
            let caseTypeChoices = _.sortBy(_.uniqBy(this.getCasesCaseTypeWise().map(i => ({
              label: i.caseType,
              value: i.caseType,
            })), 'value'), 'value');
            caseTypeChoices.splice(0, 0, { label: "All", value: "" });
            this.caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
            this.caseTypeRef.setChoiceByValue("");
            const caseTypeElement = document.getElementById("case_type");
            this.handleChoiseListener(caseTypeElement, caseTypeChoices, 'caseTypeRef');
          }
        }, 1000);
      } else if (this.activeTab === 'case_details') {
        setTimeout(() => {
          const caseData = this.cases[0] || {};
          if (caseData.id) {
            this.updateCaseDetailFilters(({ selectedCase: caseData.id }));
            if (this.case_details_caseRef) {
              this.case_details_caseRef.setChoiceByValue(caseData.id);
            }
          }
        }, 1000);
      } else if (this.activeTab === 'company') {
        setTimeout(() => {
          const companyData = this.companies[0] || {};
          if (companyData.id) {
            this.updateCompanyFilters(({ selectedCompany: companyData.id }));
            if (this.company_companyRef) {
              this.company_companyRef.setChoiceByValue(companyData.id);
            }
          }
        }, 1000);
      } else if (this.activeTab === 'gpg') {
        setTimeout(() => {
          const gpgCompany = this.gpgCompanies[0] || {};
          if (gpgCompany?.CompanyNumber && this.gpg_companyRef) {
            this.gpg_companyRef.setChoiceByValue(gpgCompany.CompanyNumber);
          }
          if (this.gpg_competitorRef) {
            this.gpg_competitorRef.clearStore();
          }
          this.updateGPGFilters({
            selectedCompany: gpgCompany?.CompanyNumber || '',
            selectedGPGCompetitors: [],
          });
        }, 1000);
      } else if (this.activeTab === 'industry') {
        this.updateIndustryFilters({
          selectedYear: "",
          selectedIndustry: "",
          selectedSubIndustry: "",
          selectedCaseType: "",
        });
        setTimeout(() => {
          if (this.industry_yearsRef) {
            let yearChoices = _.orderBy(
              _.uniqBy(
                this.cases.filter(c => c.caseDate && moment(c.caseDate, 'YYYY-MM-DD HH:mm').isValid())
                  .map(cs => ({
                    label: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
                    value: moment(cs.caseDate, 'YYYY-MM-DD HH:mm').format("YYYY"),
                  })), 'value'),
              'value',
              'desc'
            );
            yearChoices.splice(0, 0, { label: "Select Year", value: "" });
            this.industry_yearsRef.setChoices(yearChoices, "value", "label", true);
            this.industry_yearsRef.setChoiceByValue("");
            const industryYearsElement = document.getElementById('industry_years');
            this.handleChoiseListener(industryYearsElement, yearChoices, 'industry_yearsRef');
          }
          if (this.industry_industriesRef) {
            const industriesData = this.getIndustry_Industries().flatMap(ind => ind.industryData).filter(f => !!f?.GroupDesc);
            let indChoices = _.sortBy(_.uniqBy(industriesData.map(cs => ({
              label: cs.GroupDesc,
              value: cs.GroupDesc,
            })), 'value'), 'value');
            indChoices.splice(0, 0, { label: "Select Industries", value: "" });
            this.industry_industriesRef.setChoices(indChoices, "value", "label", true);
            this.industry_industriesRef.setChoiceByValue("");
            const industryYearsElement = document.getElementById('industry_industries');
            this.handleChoiseListener(industryYearsElement, indChoices, 'industry_industriesRef');
          }
          if (this.industry_sub_industriesRef) {
            const subIndustriesData = this.getIndustry_SubIndustries().flatMap(ind => ind.industryData).filter(f => !!f?.CodeDesc);
            let subIndChoices = _.sortBy(_.uniqBy(subIndustriesData.map(cs => ({
              label: cs.CodeDesc,
              value: cs.CodeDesc,
            })), 'value'), 'value');
            subIndChoices.splice(0, 0, { label: "Select Sub-Industries", value: "" })
            this.industry_sub_industriesRef.setChoices(subIndChoices, "value", "label", true);
            this.industry_sub_industriesRef.setChoiceByValue("");
            const industryYearsElement = document.getElementById('industry_sub-industries');
            this.handleChoiseListener(industryYearsElement, subIndChoices, 'industry_sub_industriesRef');
          }
          if (this.industry_caseTypeRef) {
            let caseTypeChoices = _.sortBy(_.uniqBy(this.getIndustry_CaseTypes().map(cs => ({
              label: cs.caseType,
              value: cs.caseType,
            })), 'value'), 'value');
            caseTypeChoices.splice(0, 0, { label: "Select Case Type", value: "" });
            this.industry_caseTypeRef.setChoices(caseTypeChoices, "value", "label", true);
            this.industry_caseTypeRef.setChoiceByValue("");
            const industryCaseTypeElement = document.getElementById("industry_case_type");
            this.handleChoiseListener(industryCaseTypeElement, caseTypeChoices, 'industry_caseTypeRef');
          }
        }, 1000);
      } else if (this.activeTab === 'legislation') {
        this.updateLNRFilters({ selectedTribunalType: '' });
        setTimeout(() => {
          if (this.tribunalTypeRef) {
            let tribunalTypeChoices = _.sortBy(_.uniqBy(this.cases.map(i => ({
              label: i.caseType,
              value: i.caseType,
            })), 'value'), 'value');
            tribunalTypeChoices.splice(0, 0, { label: "All", value: "" });
            this.tribunalTypeRef.setChoices(tribunalTypeChoices, "value", "label", true);
            this.tribunalTypeRef.setChoiceByValue("");
            const tribunalTypeElement = document.getElementById('tribunal_type');
            this.handleChoiseListener(tribunalTypeElement, tribunalTypeChoices, 'tribunalTypeRef');
          }
          if (this.lawTypeRef) {
            let lawTypeChoices = _.sortBy(_.uniqBy(this.lawAndRegs.filter(l => (l?.lawType || '').trim()).map(i => ({
              label: i.lawType,
              value: i.lawType,
            })), 'value'), 'value');
            lawTypeChoices.splice(0, 0, { label: "All", value: "" });
            this.lawTypeRef.setChoices(lawTypeChoices, "value", "label", true);
            this.lawTypeRef.setChoiceByValue("");
            const lawTypeElement = document.getElementById("law_type");
            this.handleChoiseListener(lawTypeElement, lawTypeChoices, 'lawTypeRef');
          }
        }, 1000);
      }
      // else if (this.activeTab === 'trials') {
      //   this.updateTrialFilter({
      //     selectedTrialDate: [],
      //     selectedTrialType: '',
      //     selectedTrialVenue: '',
      //     selectedTrialCaseNo: '',
      //     selectedTrialClaimant: '',
      //     selectedTrialPointOfLaw: '',
      //     selectedTrialHearingTime: '',
      //   });
      //   setTimeout(() => {
      //     if (this.trialsTypeRef) {
      //       let trialsTypeChoices = _.sortBy(_.uniqBy(
      //         this.getTrialsTypeWise().filter(d => d.trial_type).map(td => ({ label: td.trial_type, value: td.trial_type })),
      //         'value',
      //       ), 'value');
      //       trialsTypeChoices.splice(0, 0, { label: "All", value: "" });
      //       this.trialsTypeRef.setChoices(trialsTypeChoices, "value", "label", true);
      //       this.trialsTypeRef.setChoiceByValue('');
      //       const trialsTypeElement = document.getElementById("trials_type");
      //       this.handleChoiseListener(trialsTypeElement, trialsTypeChoices, 'trialsTypeRef');
      //     }
      //     if (this.trialsHearingTimeRef) {
      //       let trialsHearingTimeChoices = _.sortBy(_.uniqBy(
      //         this.getTrialsHearingTimeWise().filter(d => d['Jurisdiction(s)'] && d.Time)
      //           .map(td => ({ label: td.Time, value: td.Time })),
      //         'value',
      //       ), 'value');
      //       trialsHearingTimeChoices.splice(0, 0, { label: "All", value: "" });
      //       this.trialsHearingTimeRef.setChoices(trialsHearingTimeChoices, "value", "label", true);
      //       this.trialsHearingTimeRef.setChoiceByValue('');
      //       const trialsHearingTimeElement = document.getElementById("trials_hearing_time");
      //       this.handleChoiseListener(trialsHearingTimeElement, trialsHearingTimeChoices, 'trialsHearingTimeRef');
      //     }
      //     if (this.trialsVenueRef) {
      //       let trialsVenueChoices = _.sortBy(_.uniqBy(
      //         this.getTrialsVenueWise().filter(d => d.Venue).map(td => ({ label: td.Venue, value: td.Venue })),
      //         'value',
      //       ), 'value');
      //       trialsVenueChoices.splice(0, 0, { label: "All", value: "" });
      //       this.trialsVenueRef.setChoices(trialsVenueChoices, "value", "label", true);
      //       this.trialsVenueRef.setChoiceByValue('');
      //       const trialsVenueElement = document.getElementById("trials_venue");
      //       this.handleChoiseListener(trialsVenueElement, trialsVenueChoices, 'trialsVenueRef');
      //     }
      //     if (this.trialsCaseNoRef) {
      //       let trialsCaseNoChoices = _.sortBy(_.uniqBy(
      //         this.getTrialsCaseNoWise().filter(d => d['Case No.']).map(td => ({ label: td['Case No.'], value: td['Case No.'] })),
      //         'value',
      //       ), 'value');
      //       trialsCaseNoChoices.splice(0, 0, { label: "All", value: "" });
      //       this.trialsCaseNoRef.setChoices(trialsCaseNoChoices, "value", "label", true);
      //       this.trialsCaseNoRef.setChoiceByValue('');
      //       const trialsCaseNoElement = document.getElementById("trials_caseno");
      //       this.handleChoiseListener(trialsCaseNoElement, trialsCaseNoChoices, 'trialsCaseNoRef');
      //     }
      //     if (this.trialsPointOfLawRef) {
      //       let trialsPointOfLawChoices = _.uniqBy(
      //         this.getTrialsPointOfLawWise().flatMap(trlDt => trlDt['Jurisdiction(s)'].split(','))
      //           .map(code => ({ label: code.trim(), value: code.trim() })),
      //         'value',
      //       );
      //       trialsPointOfLawChoices.splice(0, 0, { label: "All", value: "" });
      //       this.trialsPointOfLawRef.setChoices(trialsPointOfLawChoices, "value", "label", true);
      //       this.trialsPointOfLawRef.setChoiceByValue('');
      //       const trialsPointOfLawElement = document.getElementById("trials_point_of_law");
      //       this.handleChoiseListener(trialsPointOfLawElement, trialsPointOfLawChoices, 'trialsPointOfLawRef');
      //     }
      //     if (this.trialsClaimantRef) {
      //       let trialsClaimantChoices = _.sortBy(_.uniqBy(
      //         this.getTrialsClaimantWise().map(clmnt => ({
      //           label: (clmnt.Claimant || '').replace(/\n/g, ', '),
      //           value: (clmnt.Claimant || '').split("\n")[0]
      //         })),
      //         'value',
      //       ), 'value');
      //       trialsClaimantChoices.splice(0, 0, { label: "All", value: "" });
      //       this.trialsClaimantRef.setChoices(trialsClaimantChoices, "value", "label", true);
      //       this.trialsClaimantRef.setChoiceByValue('');
      //       const trialsClaimantElement = document.getElementById("trials_claimant");
      //       this.handleChoiseListener(trialsClaimantElement, trialsClaimantChoices, 'trialsClaimantRef');
      //     }
      //   }, 1000);
      // }
      setTimeout(() => {
        this.isResetting = false;
      }, 1500);
    },
  },
};
</script>

<style>
select {
  border-radius: 0px;
  color: #fff;
  font-size: 13px;
  padding: 0.3rem 0.5rem;
}

.choices[data-type*=select-one]::after {
  color: #fff !important;
}

.custom_menu_list .choices__list--dropdown .choices__list {
  max-height: 180px;
}
</style>
