import mitt from 'mitt';

const emitter = mitt();

export default {
  on: (type, handler) => emitter.on(type, handler),
  off: (type, handler) => emitter.off(type, handler),
  emit: (type, event) => emitter.emit(type, event),
  all: () => emitter.all,
};
