<template>
  <div v-if="layout === 'custom'" class="position-absolute w-100 min-height-300 top-0" :style="{
    'background-image': 'url(' + image + ')',
    'background-position-y': '50%'
  }">
    <span class="mask bg-success opacity-6"></span>
  </div>
  <div v-show="layout === 'default'" class="min-height-300 position-absolute w-100 bg-trasnparent" />

  <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    :class="` ${sidebarType} ${isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
    } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`">
    <div class="d-flex flex-column h-100">
      <div style="flex: 0 1 auto;">
        <div class="sidenav-header">
          <i id="iconSidenav"
            class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
            aria-hidden="true"></i>
          <router-link class="m-0 navbar-brand text-center color-primary" to="/dashboards" style="padding-top: 10px;">
            <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
            <h6 class="logo-text">Employment Law</h6>
            <!-- <img :src="layout === 'landing' || darkMode || sidebarType === 'bg-default'? logoWhite : logo " class="navbar-brand-img h-100" alt="main_logo" /> -->
            <!-- <h2 class="py-0 mb-0 text-primary"><span class="font-weight-bold">KORT.</span></h2> -->
          </router-link>
        </div>
        <hr class="mb-0 horizontal dark" />
      </div>
      <div style="flex: 1 1 auto;">
        <sidenav-list />
      </div>
    </div>
  </aside>
</template>

<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/kort.png";
// import logo from "@/assets/img/logo-ct-dark.png";
import logoWhite from "@/assets/img/logo-ct.png";
import image from "@/assets/img/profile-layout-header.jpg";

import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoWhite,
      image
    };
  },
  computed: {
    ...mapGetters("theme", ["layout", "isRTL", "sidebarType", "darkMode"])
  },
};
</script>
<style>
.logo-text {
  color: rgb(112 41 98);
  font-size: 15px;
  padding-top: 5px;
}
</style>
