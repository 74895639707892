<template>
  <div class="card z-index-2">
    <div class="p-3 pb-0 card-header text-center">
      <div class="d-flex align-items-center gap-2 mb-2">
        <h6 class="mb-0 title-color" style="font-size: 0.8rem;">{{ title }}</h6>
        <slot name="title-header" />
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
    <div class="p-1 card-footer overflow-hidden">
      <div class="d-flex flex-nowrap overflow-auto pb-2 chart-data-type-list">
        <div v-for="(i, index) in chart.labels" :key="i" class="d-flex align-items-center">
          <div class="indicator" :style="{ 'background-color': colors[index] }" />
          <p class="label-style text-nowrap">{{ i }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "DoughnutChart",
  props: {
    id: {
      type: String,
      default: "doughnut-chart",
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "300",
    },
    dataSetBorderWidth: {
      type: Number,
      default: 0.5,
    },
    showTotalInCenter: {
      type: Boolean,
      default: false,
    },
    cropLabel: {
      type: Boolean,
      default: false,
    },
    cropLabelLength: {
      type: Number,
      default: 30,
    },
    colors: {
      type: Array,
      default() {
        return [
          "#a14590",
          "#702963",
          "#aa118f",
          "#4b2944",
        ]
      },
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        label: String,
        data: Array,
      },
    },
  },
  watch: {
    chart: {
      handler: function (newData, oldData) {
        if (
          (newData?.labels || []).toString() !== (oldData?.labels || []).toString()
          || (newData?.datasets?.data || []).toString() !== (oldData?.datasets?.data || []).toString()
        ) {
          this.updateChart();
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.updateChart();
  },
  beforeUnmount() {
    if (this.chartData) {
      this.chartData.destroy();
    }
  },
  methods: {
    updateChart() {
      // Doughnut chart
      const canvas = document.getElementById(this.id);
      if (canvas) {
        var ctx = canvas.getContext("2d");

        if (this.chartData) {
          this.chartData.destroy();
        }

        const centerText = this.showTotalInCenter ? {
          id: `${this.id}_center_text`,
          afterDatasetDraw(chart) {
            const { ctx } = chart;
            if (chart.getDatasetMeta(0)) {
              const text = Number(chart.getDatasetMeta(0).total).toFixed(0);

              ctx.save();
              if (chart.getDatasetMeta(0).data && chart.getDatasetMeta(0).data.length) {
                const y = chart.getDatasetMeta(0).data[0].y;
                const x = chart.getDatasetMeta(0).data[0].x;

                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = 'bold 30px Open Sans';
                ctx.fillStyle = '#344767';
                ctx.fillText(text, x, y);
              }
            }
          },
        } : {};

        const dataTotal = (this.chart.datasets.data || []).reduce((a, b) => Number(a) + Number(b), 0);
        const getPer = (num = 0) => Number(Number((Number(num) * 100) / dataTotal).toFixed(2));
        const getCropedLabel = (label) => {
          if (!this.cropLabel) return label;
          label = (label || '');
          return label.length <= this.cropLabelLength ? label : `${label.substring(0, this.cropLabelLength)}...`;
        }

        this.chartData = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: this.chart.labels,
            datasets: [
              {
                label: this.chart?.datasets?.label || [],
                weight: 9,
                cutout: 60,
                tension: 0.9,
                pointRadius: 2,
                borderWidth: 0,
                backgroundColor: this.colors,
                data: this.chart?.datasets?.data || [],
                fill: false,
                hoverOffset: 4
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                position: 'nearest',
                callbacks: {
                  title: () => this.chart.datasets.label,
                  label: function (tooltipItem) {
                    return `${getCropedLabel(tooltipItem.label)}: ${getPer(tooltipItem.raw || 0)}%`;
                  }
                }
              },
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
                border: {
                  display: false
                }
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
                border: {
                  display: false,
                }
              },
            },
          },
          plugins: [centerText]
        });
      }
    }
  },
};
</script>