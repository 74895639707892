<template>
  <a v-if="!alwaysExpanded" :data-bs-toggle="collapse ? 'collapse' : ''"
    :href="collapse ? `#${collapseRef}` : collapseRef" :aria-controls="collapseRef" :aria-expanded="isExpanded"
    :class="['nav-link', { className }]" v-bind="$attrs" style="padding-top: 5px; padding-bottom: 2px;"
    @click="isExpanded = !isExpanded">
    <span class="nav-link-text" :class="$store.getters.isRTL ? ' me-1' : 'ms-1'">{{ navText }}</span>
    <slot name="nav-header"></slot>
  </a>
  <a v-else style="cursor: default; padding-top: 5px; padding-bottom: 2px;" :class="['nav-link', { className }]">
    <span class="nav-link-text" :class="$store.getters.isRTL ? ' me-1' : 'ms-1'">{{ navText }}</span>
    <slot name="nav-header"></slot>
  </a>
  <div :class="alwaysExpanded ? 'collapse show' : isExpanded ? 'collapse show' : 'collapse'">
    <slot name="list"></slot>
  </div>
</template>
<script>
export default {
  name: "SidenavCollapse",
  props: {
    collapseRef: {
      type: String,
      required: true
    },
    navText: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    collapse: {
      type: Boolean,
      default: true
    },
    alwaysExpanded: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data() {
    return {
      isExpanded: false
    };
  },
  created() {
    this.isExpanded = this.expanded;
  }
};
</script>
