<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-start justify-content-lg-between mb-4 mb-lg-0">
        <div v-if="date || version" class="col-lg-2 text-xs copyright">
          <div v-if="date"><strong>Date:</strong> <span class="font-italic">
              {{ getFormatedDate(date, '', '', true) }}
            </span></div>
          <div v-if="version"><strong>Version:</strong> <span class="font-italic">{{ version }}</span></div>
        </div>
        <div class="col-lg-8">
          <div class="text-xs text-center copyright" :class="$store.getters.isRTL ? 'text-lg-end' : 'text-lg-start'">
            Some content generated by AI; please verify all critical information. © {{ new Date().getFullYear() }} <span
              class="font-weight-bold">Kort Ltd.</span> All rights reserved.
          </div>
        </div>
        <div :class="[!(date || version) ? 'col-lg-4' : 'col-lg-2']">
          <ul class="nav nav-footer justify-content-center justify-content-lg-end">
            <li class="nav-item">
              <router-link to="/privacy-policy" class="nav-link text-muted text-xs">Privacy Policy</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { getFormatedDate } from "@/utils/utils.js";

export default {
  name: "AppFooter",
  data() {
    return {
      date: '',
      version: '',
    };
  },
  computed: {
    ...mapGetters('dashboard', [
      'activeTab',
      'fetchingData',
      'casesFooterData',
      'companyFooterData',
      'gpgFooterData',
      'industryFooterData',
      'trialsFooterData',
      'lawAndRegsFooterData',
    ]),
  },
  watch: {
    activeTab: function () {
      this.loadFooterData();
    },
    fetchingData: function () {
      this.loadFooterData();
    },
  },
  mounted() {
    this.loadFooterData();
  },
  methods: {
    getFormatedDate,
    loadFooterData() {
      const dateTimeFormat = 'YYYY-MM-DD HH:mm';
      if (this.activeTab == 'cases') {
        this.date = moment(this.casesFooterData.timestamp).isValid() ? moment(this.casesFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.casesFooterData.version || '';
      } else if (this.activeTab == 'case_connections') {
        this.date = moment(this.casesFooterData.timestamp).isValid() ? moment(this.casesFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.casesFooterData.version || '';
      } else if (this.activeTab == 'company') {
        this.date = moment(this.companyFooterData.timestamp).isValid() ? moment(this.companyFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.companyFooterData.version || '';
      } else if (this.activeTab == 'gpg') {
        this.date = moment(this.gpgFooterData.timestamp).isValid() ? moment(this.gpgFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.gpgFooterData.version || '';
      } else if (this.activeTab == 'industry') {
        this.date = moment(this.industryFooterData.timestamp).isValid() ? moment(this.industryFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.industryFooterData.version || '';
      } else if (this.activeTab == 'trials') {
        this.date = moment(this.trialsFooterData.timestamp).isValid() ? moment(this.trialsFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.trialsFooterData.version || '';
      } else if (this.activeTab == 'legislation') {
        this.date = moment(this.lawAndRegsFooterData.timestamp).isValid() ? moment(this.lawAndRegsFooterData.timestamp).format(dateTimeFormat) : '';
        this.version = this.lawAndRegsFooterData.version || '';
      } else {
        this.date = '';
        this.version = '';
      }
    }
  },
};
</script>
